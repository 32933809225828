import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    token: localStorage.getItem('token') || null,
    isAuthenticated: null,
    loading: true,
    user: null,
  });

  useEffect(() => {
    const loadUser = async () => {
      if (auth.token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
      } else {
        setAuth((prevAuth) => ({
          ...prevAuth,
          isAuthenticated: false,
          loading: false,
        }));
        return;
      }

      try {
        const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
        setAuth((prevAuth) => ({
          ...prevAuth,
          isAuthenticated: true,
          loading: false,
          user: res.data,
        }));
      } catch (err) {
        console.error(err);
        setAuth((prevAuth) => ({
          ...prevAuth,
          token: null,
          isAuthenticated: false,
          loading: false,
          user: null,
        }));
        localStorage.removeItem('token');
      }
    };

    loadUser();
    // eslint-disable-next-line
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setAuth((prevAuth) => ({
      ...prevAuth,
      token,
      isAuthenticated: true,
      loading: false,
    }));
    refreshUser(); // Optionally, load user data here
  };

  const refreshUser = async () => {
    try {
      const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
      setAuth((prevAuth) => ({
        ...prevAuth,
        user: res.data,
      }));
    } catch (err) {
      console.error(err);
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    
    delete axios.defaults.headers.common['Authorization'];
    setAuth({
      token: null,
      isAuthenticated: false,
      loading: false,
      user: null,
    });
   
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, refreshUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
