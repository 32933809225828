import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Sidebaradmin from './Sidebaradmin';

const VendorRegister = () => {
  const [name, setName] = useState('');
  const [emails, setEmails] = useState(['']); // Array of strings for emails
  const [contactNumber, setContactNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleAddEmail = () => {
    setEmails([...emails, '']); // Add an empty string for a new email
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value; // Update the email at the specific index
    setEmails(newEmails);
  };

  const handleRegister = async () => {
    setLoading(true);

    // Filter out empty email strings
    const validEmails = emails.filter(email => email && email.trim() !== '');

    // Check if validEmails has at least one email
    if (validEmails.length === 0) {
      toast.error('Please enter at least one valid email address.');
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post('https://besto-hrms.onrender.com/api/auth/register-vendor', {
        name,
        emails: validEmails,  // Send array of strings
        contactNumber,
        password,
        role: 'Vendor',  
      });

      toast.success(res.data.message);
      setName('');
      setEmails(['']); // Reset to initial state
      setContactNumber('');
      setPassword('');
    } catch (error) {
      console.error('Registration failed:', error);
      toast.error('Error registering vendor possibly email id already exists',error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebaradmin />
      <div className="flex-1 p-8">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto">
          <h2 className="text-4xl font-semibold text-gray-800 mb-8">Vendor Registration</h2>
          
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Vendor Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter vendor name"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Contact Number:</label>
            <input
              type="text"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter contact number"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter password"
            />
          </div>

          {emails.map((email, index) => (
            <div key={index} className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Email {index + 1}:
              </label>
              <div className="flex space-x-4">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => handleEmailChange(index, e.target.value)}
                  className="flex-1 p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter email"
                />
                {index > 0 && (
                  <button
                    type="button"
                    className="text-red-500 font-semibold focus:outline-none"
                    onClick={() => handleRemoveEmail(index)}
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}

          <div className="flex justify-between items-center mb-6">
            <button
              type="button"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition focus:outline-none"
              onClick={handleAddEmail}
            >
              Add Another Email
            </button>
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className={`${
                loading ? 'bg-gray-400' : 'bg-green-500 hover:bg-green-600'
              } text-white px-6 py-3 rounded-lg transition focus:outline-none`}
              onClick={handleRegister}
              disabled={loading}
            >
              {loading ? 'Registering...' : 'Register Vendor'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorRegister;
