import React from 'react';


function Homepage() {
  return (
    <div className="flex flex-col min-h-screen">
      
      <main className="flex-grow bg-gray-100">
        <div className="container mx-auto py-20 text-center">
          <h1 className="text-4xl font-bold text-gray-800">Welcome to HRMS</h1>
          <p className="mt-4 text-lg text-gray-600">Your one-stop solution for HR management</p>
        </div>
      </main>
      
    </div>
  );
}

export default Homepage;
