import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EmployeeDashboard = () => {
    const { auth } = useContext(AuthContext);
    const [user, setUser] = useState(null);
    const [status, setStatus] = useState('Loading...');  // New state for status
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
                setUser(res.data);  
            } catch (err) {
                console.error(err);
            }
        };

        const fetchStatus = async () => {
            try {
                const res = await axios.get('https://besto-hrms.onrender.com/api/employee/employee/status', {
                    headers: {
                        Authorization: `Bearer ${auth.token}`
                    }
                });
                setStatus(res.data.status);  // Set the fetched status
            } catch (err) {
                console.error('Error fetching employee status:', err);
                setStatus('Error fetching status');
            }
        };

        if (auth.token) {
            fetchUser();  // Fetch employee details if token is available
            fetchStatus(); // Fetch employee status
        }
    }, [auth.token]);

    if (!auth.token || !user) {
        return <div>Loading...</div>;  // Show loading state while user data is being fetched
    }

    const handleViewProfile = () => {
        navigate('/employee-profile');
    };

    const handleViewJobs = () => {
        navigate('/job-list');
    };

    const handleViewApp = () => {
        navigate('/my-applications');
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <div className="container mx-auto py-12 px-6">
                <h1 className="text-4xl font-bold mb-6 text-center text-gray-800">Employee Dashboard</h1>
                <span className="text-gray-800">Welcome, {user.name}!</span> {/* Ensure user.name is only accessed after user data is loaded */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                    {/* Dashboard Cards */}
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Profile</h2>
                        <p className="text-gray-600">View and edit your profile details.</p>
                        <p className="mt-2 text-gray-700 font-semibold">Status: {status}</p> {/* Display the status */}
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                        onClick={handleViewProfile}>
                            View Profile
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">My Applications</h2>
                        <p className="text-gray-600">View and track your job applications.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                         onClick={handleViewApp}>
                            View Applications
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Jobs</h2>
                        <p className="text-gray-600">View and apply</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600"
                        onClick={handleViewJobs}>
                            View Jobs
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Performance Reviews</h2>
                        <p className="text-gray-600">Check your performance evaluations and feedback.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600">
                            View Reviews
                        </button>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-semibold mb-4">Company News</h2>
                        <p className="text-gray-600">Stay updated with the latest company news and announcements.</p>
                        <button className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded hover:bg-indigo-600">
                            View News
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDashboard;
