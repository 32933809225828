import React, { useState, useEffect } from 'react';
import * as base64js from 'base64-js';
import { Button, CircularProgress, Snackbar } from '@mui/material';
import { toast } from 'react-toastify';

const UpdateDeviceBluetooth = ({ user, onDeviceUpdate }) => {
  const [device, setDevice] = useState(null);
  const [authStatus, setAuthStatus] = useState('');
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [macAddress, setMacAddress] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [loading, setLoading] = useState(false);

  const requestDevice = async () => {
    try {
      setLoading(true);
      setAuthStatus('Selecting device...');
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: ['ebed0e09-033a-4bfe-8dcc-20a04fad944e'] }],
      });
      setDevice(device);
      setAuthStatus('Device selected. Connecting...');
      await connectToDevice(device);
    } catch (error) {
      console.error('Error selecting Bluetooth device:', error);
      setAuthStatus(`Failed to select a device: ${error.message}`);
      setLoading(false);
    }
  };

  const connectToDevice = async (device) => {
    try {
      const server = await device.gatt.connect();
      setAuthStatus('Connected to the device.');
      setDeviceConnected(true);
      setLoading(false);
      await getDeviceServices(server);
    } catch (error) {
      console.error('Error connecting to the Bluetooth device:', error);
      setAuthStatus(`Failed to connect: ${error.message}`);
      setLoading(false);
    }
  };

  const getDeviceServices = async (server) => {
    try {
      const service = await server.getPrimaryService('ebed0e09-033a-4bfe-8dcc-20a04fad944e');
      const characteristics = await service.getCharacteristics();
      characteristics.forEach((char) => {
        if (char.properties.read) {
          readCharacteristicValue(char);
        }
      });
    } catch (error) {
      console.error('Error getting services and characteristics:', error);
      setAuthStatus(`Failed to retrieve services or characteristics: ${error.message}`);
      setLoading(false);
    }
  };

  const readCharacteristicValue = async (characteristic) => {
    try {
      const value = await characteristic.readValue();
      const valueArray = new Uint8Array(value.buffer);
      const base64Value = base64js.fromByteArray(valueArray);
      const decodedValue = atob(base64Value);

      if (characteristic.uuid === 'c36e1c5a-fc6e-48c8-9a8e-d0b350399d0e') {
        setMacAddress(decodedValue); 
      } else if (characteristic.uuid === 'fbc47809-76ce-44fa-a2f0-676b95615473') {
        setUniqueId(decodedValue); 
      }
    } catch (error) {
      console.error('Error reading characteristic value:', error);
      setAuthStatus(`Failed to read characteristic value: ${error.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (macAddress && uniqueId) {
      updateDeviceDetails(macAddress, uniqueId);
    }
  }, [macAddress, uniqueId]);

  const updateDeviceDetails = async (macAddress, uniqueId) => {
    try {
      setLoading(true);
      const response = await fetch(`https://besto-hrms.onrender.com/api/auth/update-device/${user._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ macAddress, uniqueId }),
      });

      const data = await response.json();
      if (response.ok) {
        setAuthStatus('Device details updated successfully.');
        toast.success('Update Successful');
        onDeviceUpdate(); // Notify parent to refresh the list and close the form
      } else {
        setAuthStatus(`Failed to update device details: ${data.message}`);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating device details:', error);
      setAuthStatus(`Failed to update device details: ${error.message}`);
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
        <h3 className="text-xl font-semibold mb-4">Update Device for {user.name}</h3>
        
        {/* Request Device Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={requestDevice}
          fullWidth
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'Connect Bluetooth Device'}
        </Button>

        {/* Status Message */}
        <p className="mt-4 text-gray-700">{authStatus}</p>

        {/* Display MAC Address and Unique ID if available */}
        {macAddress && uniqueId && (
          <div className="mt-4">
            <p><strong>Device MAC Address:</strong> {macAddress}</p>
            <p><strong>Device Unique ID:</strong> {uniqueId}</p>
          </div>
        )}

        {/* Close Button */}
        <Button
          onClick={() => onDeviceUpdate()}
          className="mt-4 text-red-500"
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default UpdateDeviceBluetooth;
