import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const Sidebar = () => {
  const { logout } = useContext(AuthContext);

  return (
    <div className="w-64 h-full bg-gray-800 text-white flex flex-col p-6">
      <h2 className="text-2xl font-semibold mb-6">Menu</h2>
      <ul className="flex-1">
        <li className="mb-4">
          <Link
            to="/dashboard"
            className="block px-4 py-2 rounded-lg hover:bg-gray-700 transition"
          >
            Dashboard
          </Link>
        </li>
        <li className="mb-4">
          <Link
            to="/generate-credentials"
            className="block px-4 py-2 rounded-lg hover:bg-gray-700 transition"
          >
            Generate Credentials
          </Link>
        </li>
        <li className="mb-4">
          <Link
            to="/bluetooth-auth"
            className="block px-4 py-2 rounded-lg hover:bg-gray-700 transition"
          >
            Change Device SA
          </Link>
        </li>
        <li className="mb-4">
          <Link
            to="/saadmin-management"
            className="block px-4 py-2 rounded-lg hover:bg-gray-700 transition"
          >
            Admin Device management
          </Link>
        </li>
        <li className="mb-4">
          <Link
            to="/dashboard-admin"
            className="block px-4 py-2 rounded-lg hover:bg-gray-700 transition"
          >
            Admin Panel
          </Link>
        </li>
      </ul>
      <button
        onClick={logout}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition mt-auto"
      >
        Logout
      </button>
    </div>
  );
};

export default Sidebar;
