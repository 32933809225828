import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

const PrivateRoute = ({ children, requiredRole }) => {
  const { auth } = useContext(AuthContext);

  if (auth.loading) {
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Allow access if role matches requiredRole or if superadmin should have access to admin routes
  if (
    requiredRole &&
    (!auth.user ||
      (auth.user.role !== requiredRole &&
        !(requiredRole === "admin" && auth.user.role === "superadmin")))
  ) {
    return <Navigate to="/403" />;
  }

  return children;
};

export default PrivateRoute;
