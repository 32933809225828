import React, { useState, useContext,useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../context/AuthContext'; 

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { login, auth } = useContext(AuthContext); 
  const navigate = useNavigate();

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const res = await axios.post('https://besto-hrms.onrender.com/api/auth//login-vendor', formData);
      
      if (res && res.data && res.data.token) {
        toast.success('Login successful!');
  
        // Call the login function from AuthContext to store the token
        await login(res.data.token); // Ensure this function fetches user data
  
        // Here, we're setting a local state for redirect
        setRedirect(true);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.msg || 'Login failed!');
    }
  };
  
  // State to manage redirection
  const [redirect, setRedirect] = useState(false);
  
  // Effect to handle navigation based on user role
  useEffect(() => {
    if (redirect && auth.user) {
      if (auth.user.role === 'Employee') {
        navigate('/employee-dashboard');
      } else if (auth.user.role === 'Vendor') {
        navigate('/vendor-dashboard');
      } else {
        navigate('/');
      }
    }
  }, [redirect, auth.user, navigate]);
  
  

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
          Login
        </h2>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={onChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-indigo-200"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={onChange}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-indigo-200"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-500 text-white font-bold py-2 px-4 rounded hover:bg-indigo-600 focus:outline-none focus:bg-indigo-600"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
