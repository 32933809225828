import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  InputAdornment
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Sidebaradmin from './Sidebaradmin';

const UnscreenedCandidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [screeningData, setScreeningData] = useState({});
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [searchQuery, setSearchQuery] = useState({ name: '', contactNumber: '' });

  // Function to fetch candidates from the backend with optional search parameters
  const fetchUnscreenedCandidates = async (name = '', contactNumber = '') => {
    try {
      const response = await axios.get('https://besto-hrms.onrender.com/api/candidate/unscreened-candidates', {
        params: { name, contactNumber }
      });
      setCandidates(response.data);
    } catch (error) {
      console.error('Error fetching unscreened candidates', error);
    }
  };

  useEffect(() => {
    fetchUnscreenedCandidates();
  }, []);

  const handleInputChange = (e) => {
    setScreeningData({ ...screeningData, [e.target.name]: e.target.value });
  };

  const handleScreenCandidate = async (profileId) => {
    try {
      await axios.post(`https://besto-hrms.onrender.com/api/candidate/screen-candidate/${profileId}`, screeningData);
      alert('Candidate screened successfully!');
      setCandidates(candidates.filter(candidate => candidate._id !== profileId));
      setSelectedCandidateId(null);
    } catch (error) {
      console.error('Error screening candidate', error);
      alert('Error screening candidate');
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  const handleSearch = () => {
    fetchUnscreenedCandidates(searchQuery.name, searchQuery.contactNumber);
  };

  return (
    
    <div className="p-8 bg-gray-100 min-h-screen">
        <Sidebaradmin className="w-64 shadow-md bg-white" />
      <Typography variant="h4" gutterBottom className="text-center mb-4">
        Unscreened Candidates
      </Typography>

      {/* Search Fields */}
      <div className="mb-4 flex gap-4">
        <TextField
          label="Search by Name"
          name="name"
          variant="outlined"
          value={searchQuery.name}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Search by Contact Number"
          name="contactNumber"
          variant="outlined"
          value={searchQuery.contactNumber}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="primary" onClick={handleSearch}>
          Search
        </Button>
      </div>

      {/* Candidate List */}
      <List className="bg-white shadow-md rounded-lg p-4">
        {candidates.map(candidate => (
          <ListItem key={candidate._id} className="border-b">
            <ListItemText
              primary={`Profile ID: ${candidate._id}`}
              secondary={`Name: ${candidate.user.decryptedName}, Contact: ${candidate.user.decryptedContactNumber}, Email: ${candidate.user.emails[0]}`}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSelectedCandidateId(candidate._id)}
            >
              Screen
            </Button>
          </ListItem>
        ))}
      </List>

      {selectedCandidateId && (
        <div className="mt-8">
          <Typography variant="h5" gutterBottom>
            Screen Candidate
          </Typography>
          <form onSubmit={(e) => { e.preventDefault(); handleScreenCandidate(selectedCandidateId); }}>
            
            {/* Accordion for Sections */}
            <Accordion defaultExpanded className="mb-4">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Basic Information</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField label="Source" name="source" variant="outlined" onChange={handleInputChange} required />
                  <TextField label="Position" name="position" variant="outlined" onChange={handleInputChange} required />
                  <TextField label="Consultant ID" name="consultantID" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Candidate Name" name="candidateName" variant="outlined" onChange={handleInputChange} required />
                  <TextField label="Contact Number" name="contactNumber" variant="outlined" onChange={handleInputChange} required />
                  <TextField label="Email ID" name="emailID" variant="outlined" onChange={handleInputChange} required />
                  <TextField label="LinkedIn ID" name="linkedinID" variant="outlined" onChange={handleInputChange} />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mb-4">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Experience and CTC</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField label="Total Experience" name="totalExperience" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Relevant Experience" name="relevantExperience" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Current Shift Time" name="currentShiftTime" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Current CTC" name="currentCTC" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Expected Per Month" name="expectedPerMonth" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Negotiate Salary" name="negotiateSalary" variant="outlined" onChange={handleInputChange} />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mb-4">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Location and Preferences</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <TextField label="Employment Type" name="employmentType" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Work Mode" name="workMode" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Comfortable with BGV" name="comfortableBGV" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Country" name="country" variant="outlined" onChange={handleInputChange} required />
                  <TextField label="State" name="state" variant="outlined" onChange={handleInputChange} />
                  <TextField label="City" name="city" variant="outlined" onChange={handleInputChange} />
                  <TextField label="Notice Period" name="noticePeriod" variant="outlined" onChange={handleInputChange} />
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="mb-4">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Additional Remarks</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  label="Remark"
                  name="remark"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  onChange={handleInputChange}
                />
              </AccordionDetails>
            </Accordion>

            <Button type="submit" variant="contained" color="primary" className="w-full md:w-auto">
              Submit Screening
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UnscreenedCandidates;
