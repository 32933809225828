import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons for toggle button
import axios from 'axios';

const SidebarAdmin = () => {
  const { auth } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [openSections, setOpenSections] = useState({});
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to handle sidebar visibility

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
        setUser(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (auth.token) {
      fetchUser();
    }
  }, [auth.token]);

  const toggleSection = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative">
      {/* Toggle button */}
      <button
        onClick={toggleSidebar}
        className="text-white bg-gray-800 p-2 fixed top-4 left-4 z-50 rounded-md focus:outline-none"
      >
        {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
      </button>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-800 text-white shadow-lg transition-transform duration-300 transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } overflow-y-auto z-40`}
      >
        <div className="p-6">
          <h2 className="text-2xl font-semibold text-white mb-6">Admin Panel</h2>

          <ul className="space-y-4">
            <li>
              <button
                onClick={() => toggleSection('general')}
                className="w-full text-left text-lg font-medium text-gray-300 hover:text-white hover:bg-gray-700 p-3 rounded-lg transition"
              >
                General
              </button>
              {openSections.general && (
                <ul className="ml-4 space-y-2">
                  <li>
                    <Link to="/dashboard-admin" className="text-gray-300 hover:text-white">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/users-management" className="text-gray-300 hover:text-white">
                      User management
                    </Link>
                  </li>
                  <li>
                    <Link to="/job-post" className="text-gray-300 hover:text-white">
                      Post Job
                    </Link>
                  </li>
                  <li>
                    <Link to="/job-management" className="text-gray-300 hover:text-white">
                      Manage Jobs
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                onClick={() => toggleSection('registrations')}
                className="w-full text-left text-lg font-medium text-gray-300 hover:text-white hover:bg-gray-700 p-3 rounded-lg transition"
              >
                Registrations
              </button>
              {openSections.registrations && (
                <ul className="ml-4 space-y-2">
                  <li>
                    <Link to="/vendor-register" className="text-gray-300 hover:text-white">
                      Register Vendor
                    </Link>
                  </li>
                  <li>
                    <Link to="/employee-register" className="text-gray-300 hover:text-white">
                      Register Employee
                    </Link>
                  </li>
                  <li>
                    <Link to="/candidate-register" className="text-gray-300 hover:text-white">
                      New Candidate Screening
                    </Link>
                  </li>
                  <li>
                    <Link to="/existing-candidate-screening" className="text-gray-300 hover:text-white">
                      Existing Candidate Screening
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <button
                onClick={() => toggleSection('management')}
                className="w-full text-left text-lg font-medium text-gray-300 hover:text-white hover:bg-gray-700 p-3 rounded-lg transition"
              >
                Management
              </button>
              {openSections.management && (
                <ul className="ml-4 space-y-2">
                  <li>
                    <Link to="/application-management" className="text-gray-300 hover:text-white">
                      Application Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/employee-management" className="text-gray-300 hover:text-white">
                      Employee Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/candidate-management" className="text-gray-300 hover:text-white">
                      Candidate Management
                    </Link>
                  </li>
                  <li>
                    <Link to="/vendor-management" className="text-gray-300 hover:text-white">
                      Vendor Management
                    </Link>
                  </li>
                </ul>
              )}
            </li>

            {user && user.role === 'superadmin' && (
              <li className="mt-6">
                <Link
                  to="/dashboard"
                  className="block text-center bg-blue-500 text-white p-3 rounded-lg transition hover:bg-blue-600"
                >
                  Back to Superadmin Dashboard
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarAdmin;
