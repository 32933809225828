import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Container, TextField, Button, FormGroup, FormControlLabel, Checkbox, Paper, Typography, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import Sidebaradmin from './Sidebaradmin';

const JobPostForm = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [visibilityRoles, setVisibilityRoles] = useState(['admin', 'superadmin']);
  const [questions, setQuestions] = useState([{ questionText: "" }]);
  const [interviewRounds, setInterviewRounds] = useState(1); // State for interview rounds
  const [expiresAt, setExpiresAt] = useState(""); // State for expiration date

  const roles = ['Employee', 'Vendor','Candidate']; // Add more as needed

  const handleCreateJobPost = async () => {
    try {
      const jobPost = { 
        title, 
        description, 
        visibilityRoles, 
        questions, 
        interviewRounds, 
        expiresAt: expiresAt ? new Date(expiresAt) : null 
      };
      await axios.post("https://besto-hrms.onrender.com/api/jobs/job-posts", jobPost);
      toast.success("Job post created successfully");
      setTitle("");
      setDescription("");
      setVisibilityRoles([]);
      setQuestions([{ questionText: "" }]);
      setInterviewRounds(1);
      setExpiresAt("");
    } catch (error) {
      toast.error("Failed to create job post");
      console.error(error);
    }
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { questionText: "" }]);
  };

  const handleRemoveQuestion = (index) => {
    const newQuestions = questions.filter((_, i) => i !== index);
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = questions.map((q, i) =>
      i === index ? { ...q, questionText: value } : q
    );
    setQuestions(newQuestions);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <Sidebaradmin />
      </div>
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px', marginTop: '30px' }}>
        <Typography variant="h4" gutterBottom>
          Create Job Post
        </Typography>
        <form noValidate autoComplete="off">
          <FormGroup>
            <TextField
              label="Job Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Job Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
            <Typography variant="h6" gutterBottom>
              Visible To
            </Typography>
            {roles.map((role) => (
              <FormControlLabel
                key={role}
                control={
                  <Checkbox
                    checked={visibilityRoles.includes(role)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setVisibilityRoles([...visibilityRoles, role]);
                      } else {
                        setVisibilityRoles(visibilityRoles.filter((r) => r !== role));
                      }
                    }}
                  />
                }
                label={role}
              />
            ))}

            <Typography variant="h6" gutterBottom>
              Job Questions
            </Typography>
            {questions.map((question, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  label={`Question ${index + 1}`}
                  value={question.questionText}
                  onChange={(e) => handleQuestionChange(index, e.target.value)}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
                <IconButton onClick={() => handleRemoveQuestion(index)}>
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            ))}
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddQuestion}
              startIcon={<AddCircleOutline />}
              style={{ marginTop: '10px' }}
            >
              Add Question
            </Button>

            {/* Input for Interview Rounds */}
            <TextField
              label="Interview Rounds"
              type="number"
              value={interviewRounds}
              onChange={(e) => setInterviewRounds(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
              InputProps={{ inputProps: { min: 1 } }}
            />

            {/* Input for Expiration Date */}
            <TextField
              label="Expiration Date"
              type="date"
              value={expiresAt}
              onChange={(e) => setExpiresAt(e.target.value)}
              variant="outlined"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateJobPost}
              style={{ marginTop: '20px' }}
            >
              Create Job
            </Button>
          </FormGroup>
        </form>
      </Paper>
    </Container>
    </div>
  );
};

export default JobPostForm;
