import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebaradmin from './Sidebaradmin';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newStatus, setNewStatus] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://besto-hrms.onrender.com/api/auth/users');
        setUsers(response.data);
        setAllUsers(response.data);
      } catch (err) {
        setError('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      setUsers(allUsers);
    } else {
      const filteredUsers = allUsers.filter(
        (user) =>
          user.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          user.emails[0].toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUsers(filteredUsers);
    }
  };

  const handleStatusChange = async () => {
    try {
      await axios.patch(`https://besto-hrms.onrender.com/api/auth/users/${selectedUser._id}/status`, { accountstatus: newStatus });
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === selectedUser._id ? { ...user, accountstatus: newStatus } : user
        )
      );
      setModalOpen(false);
    } catch (err) {
      setError('Failed to update account status');
    }
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const openModal = (user) => {
    setSelectedUser(user);
    setNewStatus(user.accountstatus);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  if (loading) return <div className="text-center mt-8"><div className="spinner"></div></div>;
  if (error) return <div className="text-center mt-8 text-red-500">{error}</div>;

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <Sidebaradmin />
      </div>
      <div className="flex-1 p-6 bg-gray-100">
        <h1 className="text-3xl font-semibold text-center text-blue-700 mb-6">User List</h1>

        {/* Search Bar */}
        <div className="mb-6 flex justify-center">
          <input
            type="text"
            className="border border-gray-300 px-4 py-2 rounded-lg w-1/3 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Search by name or email..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>

        {/* User List */}
        <div className="overflow-hidden bg-white shadow rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-blue-600 text-white">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Email</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Role</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Account Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentUsers.map((user) => (
                <tr key={user._id} className="hover:bg-gray-100 transition-all duration-300">
                  <td className="px-6 py-4 whitespace-nowrap">{user.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.emails[0]}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{user.role}</td>
                  <td className={`px-6 py-4 whitespace-nowrap font-semibold ${user.accountstatus === 'Disabled' ? 'text-red-500' : 'text-green-500'}`}>
                    {user.accountstatus}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => openModal(user)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                    >
                      Change Status
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4 flex justify-center space-x-4">
          {[...Array(Math.ceil(users.length / usersPerPage))].map((_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              {index + 1}
            </button>
          ))}
        </div>

        {/* Modal */}
        {modalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
              <h2 className="text-xl font-semibold mb-4">Update Account Status</h2>
              <div className="mb-4">
                <label className="block text-gray-700">Status</label>
                <select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  className="border border-gray-300 px-4 py-2 rounded-lg w-full shadow-md"
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="Disabled">Disabled</option>
                </select>
              </div>
              <div className="flex justify-between">
                <button
                  onClick={handleStatusChange}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                  Update
                </button>
                <button
                  onClick={closeModal}
                  className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserList;
