import React, { useEffect, useState } from 'react';
import UpdateDeviceBluetooth from './UpdateDeviceBluetooth';
import Sidebar from '../Sidebar';



//Modal for updating AccountStatus
const UpdateAccountStatusModal = ({ user, onStatusUpdate, onClose }) => {
  const [accountStatus, setAccountStatus] = useState(user.accountstatus || 'Active');

  const handleStatusChange = async () => {
    try {
      const response = await fetch(`https://besto-hrms.onrender.com/api/auth/admins/${user._id}/status`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accountstatus: accountStatus }),
      });

      if (response.ok) {
        onStatusUpdate(); // Notify the parent component
        onClose(); // Close the modal
      } else {
        console.error('Failed to update account status');
      }
    } catch (error) {
      console.error('Error updating account status:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h3 className="text-xl font-semibold mb-4">Update Account Status for {user.name}</h3>
        <select
          value={accountStatus}
          onChange={(e) => setAccountStatus(e.target.value)}
          className="border rounded p-2 mb-4 w-full"
        >
          <option value="Active">Active</option>
          <option value="Inactive">Inactive</option>
          <option value="Disabled">Disabled</option>
        </select>
        <div className="flex justify-end">
          <button onClick={handleStatusChange} className="bg-blue-500 text-white px-4 py-2 rounded mr-2">
            Update
          </button>
          <button onClick={onClose} className="bg-gray-300 text-black px-4 py-2 rounded">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const AdminList = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [error, setError] = useState(null);

  // Fetch user data on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch('https://besto-hrms.onrender.com/api/auth/get-admins');
      const data = await response.json();
      setUsers(Array.isArray(data) ? data : []); // Ensure data is an array
      setError(null); // Clear previous error state
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to load users.');
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setIsDeviceModalOpen(true); // Open the device modal when a user is selected
  };

  const handleDeviceUpdate = () => {
    fetchUsers(); // Refresh user list after update
    setSelectedUser(null);
    setIsDeviceModalOpen(false); // Close the device modal
  };

  const handleStatusUpdate = () => {
    fetchUsers(); // Refresh user list after status update
    setSelectedUser(null);
    setIsStatusModalOpen(false); // Close the status modal
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="p-6 flex-1">
        <h2 className="text-2xl font-semibold mb-4">Admins</h2>

        {error && (
          <p className="text-red-500 mb-4">
            {error} Please try again later.
          </p>
        )}

        <ul className="bg-white rounded-lg shadow-lg p-4 space-y-4">
          {users.length === 0 ? (
            <p className="text-center text-gray-500">No users found.</p>
          ) : (
            users.map((user) => (
              <li
                key={user._id}
                className="group border-b py-4 cursor-pointer hover:bg-gray-50 transition duration-300"
                onClick={() => handleUserSelect(user)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-medium text-lg">{user.name}</p>
                    <p className="text-gray-500">Role: {user.role}</p>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering parent onClick
                      setSelectedUser(user);
                      setIsStatusModalOpen(true); // Open only the status modal
                      setIsDeviceModalOpen(false); // Ensure device modal stays closed
                    }}
                    className="text-blue-500 hover:underline"
                  >
                    Change Status
                  </button>
                </div>
                {/* Device details shown on hover */}
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <p className="text-sm text-gray-600">
                    Device MAC Address: {user.device?.macAddress || 'Not Set'}
                  </p>
                  <p className="text-sm text-gray-600">
                    Device Unique ID: {user.device?.uniqueId || 'Not Set'}
                  </p>
                </div>
              </li>
            ))
          )}
        </ul>

        {/* Conditional rendering of modals */}
        {selectedUser && isDeviceModalOpen && (
          <UpdateDeviceBluetooth
            user={selectedUser}
            onDeviceUpdate={handleDeviceUpdate}
          />
        )}
        {selectedUser && isStatusModalOpen && (
          <UpdateAccountStatusModal
            user={selectedUser}
            onStatusUpdate={handleStatusUpdate}
            onClose={() => setIsStatusModalOpen(false)}
          />
        )}
      </div>
    </div>
  );
};

export default AdminList;
