import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Sidebaradmin from './Sidebaradmin';

const Dashboardadmin = () => {
  const { auth, logout } = useContext(AuthContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
        setUser(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (auth.token) {
      fetchUser();
    }
  }, [auth.token]);

  return (
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <Sidebaradmin />
      </div>
      <div className="flex-1 p-6 overflow-auto">
        <h2 className="text-3xl font-semibold mb-6">Admin Dashboard</h2>
        {user ? (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <p className="text-xl mb-2">
              <strong>Welcome, {user.name}!</strong>
            </p>
            <p className="text-lg text-gray-600">Role: {user.role}</p>
            <button
              onClick={logout}
              className="mt-4 bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition"
            >
              Logout
            </button>
          </div>
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <p className="text-lg">Loading user data...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboardadmin;
