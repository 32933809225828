import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const VendorProfile = () => {
  const [vendorData, setVendorData] = useState({
    vendorId: '',
    vendorType: 'Company', 
    vendorName: '', 
    email: '', 
    contact: '', 
    companyName: '',
    address: { 
      country: '', 
      state: '', 
      city: '', 
      PINCODE: '', 
      address: '' 
    }, 
    dateOfIncoperation: '', 
    documents: {
      aadharCard: null,
      panCard: null,
      TAN: null, 
      GST: null,
      MSME: null,
    },
    bankDetails: {
      name: '', 
      account: '',
      IFSC: ''
    },
    vendorPOC: [
      {
        name: '', 
        designation: '',
        contact: '', 
        email: ''
      }
    ],
    activities: '', 
    message: '', 
  });
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVendorData((prevState) => ({ ...prevState, [name]: value }));
  };


  const navigate = useNavigate();




// Handle file input change
const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
        if (['tenth', 'twelfth', 'graduation'].includes(name)) {
            // Handle educationalDocs separately
            setVendorData((prevState) => ({
                ...prevState,
                documents: {
                    ...prevState.documents,
                    educationalDocs: { ...prevState.documents.educationalDocs, [name]: file },
                },
            }));
        } else {
            // Handle other document fields
            setVendorData((prevState) => ({
                ...prevState,
                documents: { ...prevState.documents, [name]: file },
            }));
        }
    }
};

const handleSubmit = async (e) => {
    e.preventDefault();

    // Log vendor data for debugging
    console.log("Vendor Data:", vendorData);

    // Extract the documents part to upload separately
    const { documents, ...restOfVendorData } = vendorData;

    try {
        // 1. Send non-file vendor data to vendors endpoint
        await axios.post('https://besto-hrms.onrender.com/api/vendor/vendors', restOfVendorData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        alert('Vendor details saved successfully.');

        // 2. If there are files, upload the documents
        if (documents && Object.keys(documents).length > 0) {
            await uploadFiles(documents);  // Call the function to handle file upload
        }
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        alert('Error saving vendor details. Please check the console for details.');
    }
};

// Function to upload files
const uploadFiles = async (documents) => {
    const formData = new FormData();
 
    // Append document files to FormData
    Object.keys(documents).forEach((key) => {
        const file = documents[key];
        if (key === 'educationalDocs' && file) {
            // Handle educational documents separately
            Object.keys(file).forEach((eduKey) => {
                const eduFile = file[eduKey];
                if (eduFile) {
                    formData.append(`educationalDocs[${eduKey}]`, eduFile);  // Add each educational doc file
                }
            });
        } else if (file) {
            formData.append(key, file);  // Add other document files like aadharCard, panCard, etc.
        }
    });

    try {
      console.log(formData)
        // Send the formData with the files to the upload endpoint
        await axios.post('https://besto-hrms.onrender.com/api/vendor/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        alert('Files uploaded successfully.');
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        alert('Error uploading files. Please check the console for details.');
    }
};

// Function to handle changes in bank details
const handleBankDetailsChange = (field, value) => {
  setVendorData((prevState) => ({
    ...prevState,
    bankDetails: {
      ...prevState.bankDetails,
      [field]: value,
    },
  }));
};

// Function to handle changes in the POC array
const handlePOCChange = (index, field, value) => {
  const updatedPOC = [...vendorData.vendorPOC];
  updatedPOC[index] = {
    ...updatedPOC[index],
    [field]: value,
  };

  setVendorData((prevState) => ({
    ...prevState,
    vendorPOC: updatedPOC,
  }));
};

const handleAddressChange = (field, value) => {
  setVendorData({
    ...vendorData,
    address: {
      ...vendorData.address,
      [field]: value,
    },
  });
};




return (
  <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-lg">
    <h2 className="text-2xl font-semibold mb-6">Vendor Profile</h2>

    {/* Vendor ID */}
    <div className="mb-4">
      <label className="block text-gray-700">Vendor ID</label>
      <input
        type="text"
        name="vendorId"
        value={vendorData.vendorId}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Vendor Type */}
    <div className="mb-4">
      <label className="block text-gray-700">Vendor Type</label>
      <select
        name="vendorType"
        value={vendorData.vendorType}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      >
        <option value="Individual">Individual</option>
        <option value="Company">Company</option>
      </select>
    </div>

    {/* Vendor Name */}
    <div className="mb-4">
      <label className="block text-gray-700">Vendor Name</label>
      <input
        type="text"
        name="vendorName"
        value={vendorData.vendorName}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Email */}
    <div className="mb-4">
      <label className="block text-gray-700">Email</label>
      <input
        type="email"
        name="email"
        value={vendorData.email}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Contact */}
    <div className="mb-4">
      <label className="block text-gray-700">Contact</label>
      <input
        type="text"
        name="contact"
        value={vendorData.contact}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Company Name */}
    <div className="mb-4">
      <label className="block text-gray-700">Company Name</label>
      <input
        type="text"
        name="companyName"
        value={vendorData.companyName}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Date of Incorporation */}
    <div className="mb-4">
      <label className="block text-gray-700">Date of Incorporation</label>
      <input
        type="date"
        name="dateOfIncoperation"
        value={vendorData.dateOfIncoperation}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Activities */}
    <div className="mb-4">
      <label className="block text-gray-700">Activities</label>
      <input
        type="text"
        name="activities"
        value={vendorData.activities}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>

    {/* Address Section */}
    <h3 className="text-lg font-semibold mt-6">Address</h3>
    <div className="mb-4">
      <label className="block text-gray-700">Country</label>
      <input
        type="text"
        name="country"
        value={vendorData.address.country}
        onChange={(e) => handleAddressChange('country', e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>
    <div className="mb-4">
      <label className="block text-gray-700">State</label>
      <input
        type="text"
        name="state"
        value={vendorData.address.state}
        onChange={(e) => handleAddressChange('state', e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>
    <div className="mb-4">
      <label className="block text-gray-700">City</label>
      <input
        type="text"
        name="city"
        value={vendorData.address.city}
        onChange={(e) => handleAddressChange('city', e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>
    <div className="mb-4">
      <label className="block text-gray-700">PINCODE</label>
      <input
        type="text"
        name="PINCODE"
        value={vendorData.address.PINCODE}
        onChange={(e) => handleAddressChange('PINCODE', e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        required
      />
    </div>
    <div className="mb-4">
      <label className="block text-gray-700">Address</label>
      <textarea
        name="address"
        value={vendorData.address.address}
        onChange={(e) => handleAddressChange('address', e.target.value)}
        className="w-full p-2 border border-gray-300 rounded"
        rows={4}
      />
    </div>

    {/* Bank Details */}
    <h3 className="text-lg font-semibold mt-6">Bank Details</h3>
    <div className="mb-4 grid grid-cols-2 gap-4">
      <div>
        <label className="block text-gray-700">Bank Name</label>
        <input
          type="text"
          name="bankName"
          value={vendorData.bankDetails.name}
          onChange={(e) => handleBankDetailsChange('name', e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div>
        <label className="block text-gray-700">Account Number</label>
        <input
          type="text"
          name="account"
          value={vendorData.bankDetails.account}
          onChange={(e) => handleBankDetailsChange('account', e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
      <div>
        <label className="block text-gray-700">IFSC Code</label>
        <input
          type="text"
          name="IFSC"
          value={vendorData.bankDetails.IFSC}
          onChange={(e) => handleBankDetailsChange('IFSC', e.target.value)}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>
    </div>

    {/* POC Details */}
    <h3 className="text-lg font-semibold mt-6">Point of Contact</h3>
    {vendorData.vendorPOC.map((poc, index) => (
      <div className="mb-4 grid grid-cols-2 gap-4" key={index}>
        <div>
          <label className="block text-gray-700">POC Name</label>
          <input
            type="text"
            name={`pocName_${index}`}
            value={poc.name}
            onChange={(e) => handlePOCChange(index, 'name', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">POC Designation</label>
          <input
            type="text"
            name={`pocDesignation_${index}`}
            value={poc.designation}
            onChange={(e) => handlePOCChange(index, 'designation', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">POC Contact</label>
          <input
            type="text"
            name={`pocContact_${index}`}
            value={poc.contact}
            onChange={(e) => handlePOCChange(index, 'contact', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">POC Email</label>
          <input
            type="email"
            name={`pocEmail_${index}`}
            value={poc.email}
            onChange={(e) => handlePOCChange(index, 'email', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>
    ))}

    {/* Message */}
    <div className="mb-4">
      <label className="block text-gray-700">Message</label>
      <textarea
        name="message"
        value={vendorData.message}
        onChange={handleChange}
        className="w-full p-2 border border-gray-300 rounded"
        rows={4}
      />
    </div>

    {/* Submit Button */}
    <button
      type="submit"
      className="w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-700"
    >
      Submit Vendor Profile
    </button>
  </form>
);


};

export default VendorProfile;
