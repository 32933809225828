import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Box,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Modal,
  Divider,
} from '@mui/material';
import axios from 'axios';
import Sidebaradmin from './Sidebaradmin';

const CandidateManagement = () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trackData, setTrackData] = useState(null);
  const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    status: '',
    name: '',
    contactNumber: '',
    keySkills: '',
    experience: '',
    preferredWorkModel: '',
  });



  const fetchCandidates = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://besto-hrms.onrender.com/api/candidate/candidatess', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          ...filters,
        },
      });
      console.log(response.data.candidates)
      setCandidates(response.data.candidates);
      setTotalCandidates(response.data.totalPages * rowsPerPage);
    } catch (error) {
      console.error('Error fetching candidates:', error);
      alert('Failed to fetch candidates. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

 

  const openViewModal = (candidate) => {
    setSelectedCandidate(candidate);
    setIsModalOpen(true);
  };

  const openTrackModal = async (candidateId) => {
    setLoading(true);
    try {
      console.log(candidateId)
      if (!candidateId || candidateId.length !== 24) {
        throw new Error('Invalid candidate ID');
      }
      const response = await axios.get(`https://besto-hrms.onrender.com/api/candidate/applications/${candidateId}`);
      setTrackData(response.data);
      setIsTrackModalOpen(true);
    } catch (error) {
      console.error('Error fetching tracking details:', error);
      alert('Failed to fetch tracking details. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const closeTrackModal = () => {
    setIsTrackModalOpen(false);
    setTrackData(null);
  };

  const handleStatusChange = async (candidateId, status) => {
    try {
      await axios.put(`https://besto-hrms.onrender.com/api/candidate/candidates/${candidateId}/status`, { status });
      alert('Status updated successfully');
      fetchCandidates();
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again.');
    }
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleApplyFilters = () => {
    setPage(0); // Reset page to 0 on applying filters
    fetchCandidates(); // Fetch candidates with updated filters
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    fetchCandidates(); // Fetch new page data
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
    fetchCandidates(); // Fetch candidates with updated rows per page
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [outcomeFilter, setOutcomeFilter] = useState('');

  const filteredData = trackData?.filter((item) => {
    const matchesTitle = item.jobId?.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = statusFilter ? item.interview?.status === statusFilter : true;
    const matchesOutcome = outcomeFilter ? item.interview?.outcome === outcomeFilter : true;
    return matchesTitle && matchesStatus && matchesOutcome;
  });

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebaradmin className="w-64 shadow-md bg-white" />
      <Container sx={{ mt: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
            Candidate Management
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: 'wrap' }}>
            <TextField
              label="Name"
              name="name"
              variant="outlined"
              value={filters.name}
              onChange={handleFilterChange}
            />
            <TextField
              label="Contact Number"
              name="contactNumber"
              variant="outlined"
              value={filters.contactNumber}
              onChange={handleFilterChange}
            />
            <TextField
              label="Key Skills"
              name="keySkills"
              variant="outlined"
              value={filters.keySkills}
              onChange={handleFilterChange}
            />
            <TextField
              label="Experience (years)"
              name="experience"
              type="number"
              variant="outlined"
              value={filters.experience}
              onChange={handleFilterChange}
            />
            <TextField
              label="Preferred Work Model"
              name="preferredWorkModel"
              variant="outlined"
              value={filters.preferredWorkModel}
              onChange={handleFilterChange}
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
                label="Status"
              >
                <MenuItem value="">All Status</MenuItem>
                <MenuItem value="Verified">Verified</MenuItem>
                <MenuItem value="Screening Done">Screening Done</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleApplyFilters}>
              Apply Filters
            </Button>
          </Box>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Contact</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {candidates.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          No candidates found.
                        </TableCell>
                      </TableRow>
                    ) : (
                      candidates.map((candidate) => (
                        <TableRow key={candidate._id}>
                          <TableCell>{candidate.user.decryptedName}</TableCell>
                          <TableCell>{candidate.user.decryptedContactNumber}</TableCell>
                          <TableCell>{candidate.status}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ mr: 1 }}
                              onClick={() => openViewModal(candidate)}
                            >
                              View
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              sx={{ mr: 1 }}
                              onClick={() => openTrackModal(candidate.user._id)}
                            >
                              Track
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => handleStatusChange(candidate._id, 'Screening Done')}
                            >
                              Verify
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleStatusChange(candidate._id, 'Not Verified')}
                            >
                              Reject
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={totalCandidates}
                page={page}
                onPageChange={handlePageChange}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </>
          )}
        </Paper>
        {/* Modal for Candidate Details */}
        {/* Candidate Details Modal */}
<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
  <Box sx={{
    p: 4,
    backgroundColor: '#f9f9f9',
    maxWidth: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
    borderRadius: '10px',
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
    margin: 'auto',
  }}>
    <Typography variant="h5" sx={{ mb: 2 }}>
      Candidate Details
    </Typography>
    {selectedCandidate && (
      <>
        {/* Profile Data */}
        <Typography><strong>Name:</strong> {selectedCandidate.user.decryptedName}</Typography>
        <Typography><strong>Contact Number:</strong> {selectedCandidate.user.decryptedContactNumber}</Typography>
        <Typography><strong>Email:</strong> {selectedCandidate.user.emails[0]}</Typography>
        <Typography><strong>Date of Birth:</strong> {new Date(selectedCandidate.dateOfBirth).toLocaleDateString()}</Typography>
        <Typography><strong>Gender:</strong> {selectedCandidate.gender}</Typography>
        <Typography><strong>Current Company:</strong> {selectedCandidate.currentCompany}</Typography>
        <Typography><strong>Total Experience:</strong> {selectedCandidate.totalExperience} years</Typography>
        
        <Divider sx={{ my: 2 }} />

        {/* Screening Data */}
        <Typography><strong>Status:</strong> {selectedCandidate.status}</Typography>
        <Typography><strong>Preferred Location:</strong> {selectedCandidate.preferredLocation}</Typography>
        <Typography><strong>Current CTC:</strong> {selectedCandidate.currentCTC}</Typography>
        <Typography><strong>Relevant Experience:</strong> {selectedCandidate.relevantExperience} years</Typography>
        <Typography><strong>Salary Negotiable:</strong> {selectedCandidate.salaryNegotiable ? 'Yes' : 'No'}</Typography>
        <Typography><strong>Willing to Relocate:</strong> {selectedCandidate.willingToRelocate ? 'Yes' : 'No'}</Typography>
        <Typography><strong>Willing to Travel:</strong> {selectedCandidate.willingToTravel ? 'Yes' : 'No'}</Typography>

        <Divider sx={{ my: 2 }} />
        
        <Button variant="contained" color="primary" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>
      </>
    )}
  </Box>
</Modal>

        {/* Tracking Modal */}
<Modal open={isTrackModalOpen} onClose={closeTrackModal}>
  <Box
    sx={{
      p: 4,
      backgroundColor: '#fff',
      maxWidth: '800px',
      maxHeight: '80vh',
      overflowY: 'auto',
      borderRadius: '10px',
      boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
      margin: 'auto',
    }}
  >
    <Typography variant="h6" gutterBottom>
      Job Applications
    </Typography>
    <Divider sx={{ mb: 2 }} />

    <TextField
      label="Search by Job Title"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      fullWidth
      variant="outlined"
      sx={{ mb: 2 }}
    />

    <FormControl variant="outlined" fullWidth sx={{ mb: 2 }}>
      <InputLabel>Status</InputLabel>
      <Select
        label="Status"
        value={statusFilter}
        onChange={(e) => setStatusFilter(e.target.value)}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="Scheduled">Scheduled</MenuItem>
        <MenuItem value="Completed">Completed</MenuItem>
      </Select>
    </FormControl>

    <Table>
      <TableHead>
        <TableRow>
          <TableCell><strong>Job Title</strong></TableCell>
          <TableCell><strong>Interview Status</strong></TableCell>
          <TableCell><strong>Outcome</strong></TableCell>
          <TableCell><strong>Scheduled Date</strong></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((item) => (
            <TableRow key={item._id}>
              <TableCell>
                <Box sx={{ p: 2, backgroundColor: '#f9f9f9', borderRadius: '8px', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)' }}>
                  <Typography variant="subtitle1"><strong>{item.jobId?.title || "N/A"}</strong></Typography>
                  <Typography variant="caption" color="textSecondary">{item.jobId?.description || "N/A"}</Typography>
                </Box>
              </TableCell>
              <TableCell>{item.interview?.status || "Under Review"}</TableCell>
              <TableCell>{item.interview?.outcome || "Not scheduled"}</TableCell>
              <TableCell>
                {item.interview?.scheduledDate
                  ? new Date(item.interview.scheduledDate).toLocaleString()
                  : "N/A"}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4} align="center">
              No matching applications found.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>

    {filteredData && filteredData.length > 0 && (
      <>
        <Divider sx={{ my: 3 }} />
        <Typography variant="h6" gutterBottom>Application Details</Typography>
        {filteredData.map((item) => (
          <Box key={item._id} sx={{ mb: 4, p: 3, backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" gutterBottom>
              <strong>Application ID:</strong> {item._id}
            </Typography>
            <Typography variant="subtitle1"><strong>Applied At:</strong> {new Date(item.appliedAt).toLocaleString()}</Typography>
            <Typography variant="subtitle1"><strong>Status:</strong> {item.interview?.status || "Under Review"}</Typography>
            <Typography variant="subtitle1"><strong>Outcome:</strong> {item.interview?.outcome || "Not scheduled"}</Typography>
            <Typography variant="subtitle1"><strong>Scheduled Date:</strong> {item.interview?.scheduledDate ? new Date(item.interview.scheduledDate).toLocaleString() : "N/A"}</Typography>

            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>Answers</Typography>
            {item.answers && item.answers.length > 0 ? (
              item.answers.map((answer, answerIndex) => (
                <Box key={answerIndex} sx={{ mb: 1, p: 2, backgroundColor: '#fff', borderRadius: '4px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}>
                  <Typography variant="subtitle2"><strong>Question:</strong> {answer.questionText || "Question not available"}</Typography>
                  <Typography variant="subtitle2"><strong>Answer:</strong> {answer.answerText || "No answer provided"}</Typography>
                </Box>
              ))
            ) : (
              <Typography variant="subtitle2">No answers provided.</Typography>
            )}
          </Box>
        ))}
      </>
    )}
  </Box>
</Modal>

      </Container>
    </div>
  );
};

export default CandidateManagement;
