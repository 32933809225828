import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import { AuthContext } from '../context/AuthContext'; 

const Navbar = () => {
  const { auth, logout } = useContext(AuthContext); 
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); 
    navigate("/"); 
  };

  return (
    <nav className="bg-blue-600 text-white shadow-md">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <div className="text-2xl font-bold tracking-wide">
          <Link to="/" className="hover:text-gray-200 transition">BestoHRMS</Link>
        </div>
        <div className="hidden md:flex space-x-6">
          <Link to="/job-list" className="hover:text-gray-300 transition">Jobs</Link>
          <Link to="/services" className="hover:text-gray-300 transition">Services</Link>
          <Link to="/contact" className="hover:text-gray-300 transition">Contact</Link>
        </div>
        <div className="flex items-center space-x-4">
          {!auth.token ? (
            <Link
              to="/login"
              className="bg-white text-blue-600 px-4 py-2 rounded hover:bg-gray-100 transition"
            >
              Login
            </Link>
          ) : (
            <button
              onClick={handleLogout}
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition"
            >
              Logout
            </button>
          )}
        </div>
        {/* Mobile menu button */}
        <div className="md:hidden">
          <button className="text-white hover:text-gray-300 focus:outline-none">
            {/* Add an icon for the menu */}
            <span className="material-icons">menu</span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
