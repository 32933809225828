import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Container,
  Button,
  Typography,
  Paper,
  IconButton,
  Modal,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Divider,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers"; // Updated import
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3"; // Import the date adapter
import Sidebaradmin from "./Sidebaradmin";


const JobManagement = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null); // Track selected job
  const [open, setOpen] = useState(false); // Modal state
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editInterviewRounds, setEditInterviewRounds] = useState(1);
  const [editStatus, setEditStatus] = useState("Active");
  const [editQuestions, setEditQuestions] = useState([]); // State for questions
  const [editExpirationDate, setEditExpirationDate] = useState(new Date()); // New state for expiration date

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get("https://besto-hrms.onrender.com/api/jobs/job-posts");
      setJobs(response.data);
    } catch (error) {
      toast.error("Failed to fetch jobs");
    }
  };

  const handleDeleteJob = async (id) => {
    if (window.confirm("Are you sure you want to delete this job post?")) {
      try {
        await axios.delete(`https://besto-hrms.onrender.com/api/jobs/job-posts/${id}`);
        toast.success("Job post deleted successfully");
        fetchJobs();
      } catch (error) {
        toast.error("Failed to delete job post");
      }
    }
  };

  const handleOpenModal = (job) => {
    setSelectedJob(job);
    setEditTitle(job.title);
    setEditDescription(job.description);
    setEditInterviewRounds(job.interviewRounds);
    setEditStatus(job.status);
    setEditQuestions(job.questions || []); // Initialize questions
    setEditExpirationDate(new Date(job.expiresAt)); // Set initial expiration date
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setSelectedJob(null);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...editQuestions];
    newQuestions[index].questionText = value;
    setEditQuestions(newQuestions);
  };

  const addQuestion = () => {
    setEditQuestions([...editQuestions, { questionText: "" }]);
  };

  const removeQuestion = (index) => {
    const newQuestions = editQuestions.filter((_, i) => i !== index);
    setEditQuestions(newQuestions);
  };

  const handleEditJob = async () => {
    if (!selectedJob) return;

    if (editStatus === "Active" && (!editExpirationDate || isNaN(editExpirationDate.getTime()))) {
      toast.error("Please set a valid expiration date for active jobs");
      return;
    }

    try {
      const updatedJob = {
        title: editTitle,
        description: editDescription,
        interviewRounds: editInterviewRounds,
        status: editStatus,
        questions: editQuestions, // Send updated questions
        expiresAt: editExpirationDate, // Include expiration date
      };
      await axios.put(`https://besto-hrms.onrender.com/api/jobs/job-posts/${selectedJob._id}`, updatedJob);
      toast.success("Job post updated successfully");
      fetchJobs(); // Refresh the job list
      handleCloseModal(); // Close the modal
    } catch (error) {
      toast.error("Failed to update job post");
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
    <div className="flex h-screen bg-gray-100">
      <div className="w-64 bg-white shadow-md">
        <Sidebaradmin />
      </div>
      <Container maxWidth="md">
        <Paper elevation={3} style={{ padding: "20px", marginTop: "30px" }}>
          <Typography variant="h4" gutterBottom>
            Job Management
          </Typography>
          {jobs.length > 0 ? (
            jobs.map((job) => (
              <Paper key={job._id} style={{ padding: "10px", marginBottom: "15px" }}>
                <Typography variant="h6">{job.title}</Typography>
                <Typography variant="body1">{job.description}</Typography>
                <Typography variant="body2">
                  Interview Rounds: {job.interviewRounds} | Expiration Date:{" "}
                  {new Date(job.expiresAt).toLocaleDateString()} | Status: {job.status}
                </Typography>
                <div>
                  <IconButton onClick={() => handleOpenModal(job)}>
                    <Edit color="primary" />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteJob(job._id)}>
                    <Delete color="error" />
                  </IconButton>
                </div>
              </Paper>
            ))
          ) : (
            <Typography>No job posts available</Typography>
          )}

          {/* Modal for editing job */}
          <Modal open={open} onClose={handleCloseModal}>
            <Paper
              elevation={5}
              style={{
                padding: "30px",
                margin: "auto",
                marginTop: "10%",
                width: "550px",
                maxHeight: "70vh",
                overflowY: "auto",
                backgroundColor: "#f0f0f0",
                borderRadius: "10px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Typography variant="h5" gutterBottom style={{ fontWeight: "bold" }}>
                Edit Job Post
              </Typography>
              <Divider style={{ marginBottom: "15px" }} />
              <TextField
                label="Title"
                value={editTitle}
                onChange={(e) => setEditTitle(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Description"
                value={editDescription}
                onChange={(e) => setEditDescription(e.target.value)}
                multiline
                rows={3}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Interview Rounds"
                type="number"
                value={editInterviewRounds}
                onChange={(e) => setEditInterviewRounds(e.target.value)}
                fullWidth
                margin="normal"
                InputProps={{ inputProps: { min: 1 } }}
                variant="outlined"
              />
              <FormControl fullWidth margin="normal" variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  value={editStatus}
                  onChange={(e) => setEditStatus(e.target.value)}
                  label="Status"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Expired">Expired</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>

              {editStatus === "Active" && (
                <DatePicker
                  label="Expiration Date"
                  value={editExpirationDate}
                  onChange={(date) => setEditExpirationDate(date)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                />
              )}

              <Divider style={{ margin: "20px 0" }} />
              <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Questions
              </Typography>
              {editQuestions.map((question, index) => (
                <div key={index} style={{ marginBottom: "10px" }}>
                  <TextField
                    label={`Question ${index + 1}`}
                    value={question.questionText}
                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                  />
                  <Button
                    color="secondary"
                    onClick={() => removeQuestion(index)}
                    style={{ marginTop: "5px" }}
                  >
                    Remove
                  </Button>
                </div>
              ))}
              <Button
                variant="outlined"
                onClick={addQuestion}
                style={{ marginTop: "10px", display: "block" }}
              >
                Add Question
              </Button>

              <Divider style={{ margin: "20px 0" }} />
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditJob}
                style={{ marginTop: "10px", width: "100%", padding: "10px", fontWeight: "bold" }}
              >
                Save Changes
              </Button>
            </Paper>
          </Modal>
        </Paper>
      </Container>
    </div>
    </LocalizationProvider>
  );
};

export default JobManagement;
