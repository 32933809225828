import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Sidebaradmin from "./Sidebaradmin";
import { Country, State, City } from 'country-state-city';

const CandidateRegister = () => {
  const [candidateData, setCandidateData] = useState({
    source: "",
    portalName: "",
    position: "",
    consultantID: "",
    candidateName: "",
    contactNumber: "",
    emailID: "",
    linkedinID: "",
    totalExperience: "",
    relevantExperience: "",
    currentShiftTime: "",
    currentCTC: "",
    expectedPerMonth: "",
    negotiateSalary: "",
    employmentType: "",
    workMode: "",
    comfortableBGV: "",
    location: "",
    noticePeriod: "",
    remark: "",
  });

  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const countries = Country.getAllCountries();

  const handleChange = (e) => {
    setCandidateData({
      ...candidateData,
      [e.target.name]: e.target.value,
    });
  };

  // Update states whenever the country changes
  useEffect(() => {
    if (candidateData.country) {
      setStates(State.getStatesOfCountry(candidateData.country));
      setCities([]); // Clear cities when the country changes
    }
  }, [candidateData.country]);

  // Update cities whenever the state changes
  useEffect(() => {
    if (candidateData.state) {
      setCities(City.getCitiesOfState(candidateData.country,candidateData.state));
    }
  }, [candidateData.state]);

  const generatePassword = (length = 12) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };
  
  const handleRegister = async () => {  
    setLoading(true);
    try {
      // Step 1: Register candidate
      const registerRes = await axios.post(
        "https://besto-hrms.onrender.com/api/candidate/register-candidate-screening-data",
        candidateData
      );
      toast.success("Candidate registered successfully!");
  
      // Step 2: Create login credentials using registered candidate details
      const { emailID, contactNumber, candidateName, _id: screeningID } = registerRes.data;
      const password = generatePassword(); // Generate a unique password
  
      const loginRes = await axios.post(
        "https://besto-hrms.onrender.com/api/candidate/create-login-candidate-credentials",
        { email: emailID, password, screeningID, name: candidateName, contactNumber, role: "Candidate",status:"Screening done" }
      );
  
      toast.success("Login credentials created successfully!");
  
      // Reset candidate data state
      setCandidateData({
        source: "",
        portalName: "",
        position: "",
        consultantID: "",
        candidateName: "",
        contactNumber: "",
        emailID: "",
        linkedinID: "",
        totalExperience: "",
        relevantExperience: "",
        currentShiftTime: "",
        currentCTC: "",
        expectedPerMonth: "",
        negotiateSalary: "",
        employmentType: "",
        workMode: "",
        comfortableBGV: "",
        location: "",
        noticePeriod: "",
        remark: "",
      });
    } catch (error) {
      console.error("Registration or login creation failed:", error);
      toast.error("Error during candidate registration or login creation");
    } finally {
      setLoading(false);
    }
  };
  
  


  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebaradmin />
      <div className="flex-1 p-8">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto">
          <h2 className="text-4xl font-semibold text-gray-800 mb-8">
            Candidate Registration
          </h2>

          {/* Source with Portal Name */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Source:
            </label>
            <select
              name="source"
              value={candidateData.source}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select Source</option>
              <option value="Portal">Portal</option>
              <option value="Internal">Internal</option>
            </select>
          </div>
          {candidateData.source === "Portal" && (
            <div className="mb-6">
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Portal Name:
              </label>
              <input
                type="text"
                name="portalName"
                value={candidateData.portalName}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg"
                placeholder="Enter portal name"
              />
            </div>
          )}

          {/* Position */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Position:
            </label>
            <input
              type="text"
              name="position"
              value={candidateData.position}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter position"
            />
          </div>

          {/* Consultant ID */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Consultant ID:
            </label>
            <input
              type="text"
              name="consultantID"
              value={candidateData.consultantID}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter consultant ID"
            />
          </div>

          {/* Candidate Name */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Candidate Name:
            </label>
            <input
              type="text"
              name="candidateName"
              value={candidateData.candidateName}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter candidate name"
            />
          </div>

          {/* Contact Number */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Contact Number:
            </label>
            <input
              type="text"
              name="contactNumber"
              value={candidateData.contactNumber}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter contact number"
              pattern="\d*"
            />
          </div>

          {/* Email ID */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Email ID:
            </label>
            <input
              type="email"
              name="emailID"
              value={candidateData.emailID}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter email ID"
            />
          </div>

          {/* LinkedIn ID */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              LinkedIn ID:
            </label>
            <input
              type="url"
              name="linkedinID"
              value={candidateData.linkedinID}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter LinkedIn profile link"
            />
          </div>

          {/* Experience Fields */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Total Experience:
            </label>
            <input
              type="text"
              name="totalExperience"
              value={candidateData.totalExperience}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter total experience (Years and Months)"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Relevant Experience:
            </label>
            <input
              type="text"
              name="relevantExperience"
              value={candidateData.relevantExperience}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter relevant experience (Years and Months)"
            />
          </div>

          <div className="mb-6">
  <label className="block text-lg font-medium text-gray-700 mb-2">
    Current Shift Time
  </label>
  <div className="flex space-x-2">
    <input
      type="time"
      name="shiftStartTime"
      value={candidateData.shiftStartTime || ""}
      onChange={(e) => {
        setCandidateData({
          ...candidateData,
          shiftStartTime: e.target.value,
          currentShiftTime: `${e.target.value} to ${candidateData.shiftEndTime || ""}`,
        });
      }}
      className="w-full p-3 border border-gray-300 rounded-lg"
      placeholder="Start Time"
    />
    <span className="self-center">to</span>
    <input
      type="time"
      name="shiftEndTime"
      value={candidateData.shiftEndTime || ""}
      onChange={(e) => {
        setCandidateData({
          ...candidateData,
          shiftEndTime: e.target.value,
          currentShiftTime: `${candidateData.shiftStartTime || ""} to ${e.target.value}`,
        });
      }}
      className="w-full p-3 border border-gray-300 rounded-lg"
      placeholder="End Time"
    />
  </div>
</div>
          {/* Current CTC */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Current CTC (In LPA or LPM):
            </label>
            <input
              type="text"
              name="currentCTC"
              value={candidateData.currentCTC}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter current CTC"
            />
          </div>

          {/* Expected Per Month */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Expected Per Month Including 10% TAXES (In LPA or LPM):
            </label>
            <input
              type="text"
              name="expectedPerMonth"
              value={candidateData.expectedPerMonth}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter expected per month salary"
            />
          </div>

          {/* Negotiate Salary */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Negotiate Salary:
            </label>
            <select
              name="negotiateSalary"
              value={candidateData.negotiateSalary}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          {/* Employment Type */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Employment Type:
            </label>
            <select
              name="employmentType"
              value={candidateData.employmentType}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select</option>
              <option value="Full-time">Full-time</option>
              <option value="Part-time">Part-time</option>
              <option value="Contract">Contract</option>
            </select>
          </div>

          {/* Work Mode */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Work Mode:
            </label>
            <select
              name="workMode"
              value={candidateData.workMode}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select</option>
              <option value="Remote">Remote</option>
              <option value="Onsite">Onsite</option>
              <option value="Hybrid">Hybrid</option>
            </select>
          </div>

          {/* Comfortable with BGV */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Comfortable with BGV:
            </label>
            <select
              name="comfortableBGV"
              value={candidateData.comfortableBGV}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

         {/* Location (Country, State, City) */}
         <div className="mb-6">
            {/* Country Selector */}
            <label className="block text-lg font-medium text-gray-700 mb-2">Country:</label>
            <select
              name="country"
              value={candidateData.country}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select Country</option>
              {countries.map((country) => (
                <option key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </option>
              ))}
            </select>

            {/* State Selector */}
            <label className="block text-lg font-medium text-gray-700 mb-2">State:</label>
            <select
              name="state"
              value={candidateData.state}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select State</option>
              {states.map((state) => (
                <option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </option>
              ))}
            </select>

            {/* City Selector */}
            <label className="block text-lg font-medium text-gray-700 mb-2">City:</label>
            <select
              name="city"
              value={candidateData.city}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select City</option>
              {cities.map((city) => (
                <option key={city.name} value={city.name}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          {/* Notice Period */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Notice Period:
            </label>
            <select
              name="noticePeriod"
              value={candidateData.noticePeriod}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
            >
              <option value="">Select</option>
              <option value="15">15 days</option>
              <option value="30">30 days</option>
              <option value="45">45 days</option>
              <option value="60">60 days</option>
              <option value="90">90 days</option>
              <option value="Serving Notice Period">
                Serving Notice Period
              </option>
            </select>
          </div>

          {/* Remark */}
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">
              Remark:
            </label>
            <textarea
              name="remark"
              value={candidateData.remark}
              onChange={handleChange}
              className="w-full p-3 border border-gray-300 rounded-lg"
              placeholder="Enter remarks"
            ></textarea>
          </div>

          {/* Repeat structure for currentShiftTime, currentCTC, expectedPerMonth, etc. */}

          <div className="flex justify-end">
            <button
              type="button"
              className={`${
                loading ? "bg-gray-400" : "bg-green-500 hover:bg-green-600"
              } text-white px-6 py-3 rounded-lg transition focus:outline-none`}
              onClick={handleRegister}
              disabled={loading}
            >
              {loading ? "Registering..." : "Register Candidate"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateRegister;
