import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AdminLogin = () => {
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    backupCode: '', // New field for backup code
  });

  const [loading, setLoading] = useState(false);
  const { login, auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const { name, password, backupCode } = formData;
  const [redirect, setRedirect] = useState(false);
  const [deviceConnectionFailed, setDeviceConnectionFailed] = useState(false);
  const connectionAttempted = useRef(false); // Track if connection attempt has been made

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      let deviceDetails = null;
      if (!connectionAttempted.current) {
        try {
          deviceDetails = await connectToBluetoothDevice();
        } catch (error) {
          connectionAttempted.current = true; // Set flag to indicate an attempt was made
          setDeviceConnectionFailed(true);
          toast.warn('Failed to connect to the Bluetooth device. Using backup code if provided.');
        }
      }

      // Send login request
      const res = await axios.post('https://besto-hrms.onrender.com/api/auth/login-admin', {
        name,
        password,
        macAddress: deviceDetails ? deviceDetails.macAddress : null,
        uniqueId: deviceDetails ? deviceDetails.uniqueId : null,
        backupCode, // Send backup code to backend
      });

      // Log in the user
      login(res.data.token);

      // Notify user of success
      toast.success('Login successful!');
      setLoading(false);

      // Trigger redirection after login
      setRedirect(true);
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data && err.response.data.msg) {
        toast.error(err.response.data.msg);
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
  };

  useEffect(() => {
    if (redirect && auth.user) {
      if (auth.user.role === 'superadmin') {
        navigate('/dashboard');
      } else if (auth.user.role === 'admin') {
        navigate('/dashboard-admin');
      } else {
        navigate('/');
      }
    }
  }, [redirect, auth.user, navigate]);

  // Helper function to connect to Bluetooth and get MAC address and Unique ID
  const connectToBluetoothDevice = async () => {
    if (connectionAttempted.current) {
      throw new Error('Device connection has already failed. Skipping further attempts.');
    }

    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: ['ebed0e09-033a-4bfe-8dcc-20a04fad944e'] }],
      });

      const server = await device.gatt.connect();
      const service = await server.getPrimaryService('ebed0e09-033a-4bfe-8dcc-20a04fad944e');
      const characteristics = await service.getCharacteristics();

      let macAddress = '';
      let uniqueId = '';

      for (const char of characteristics) {
        const value = await char.readValue();
        const valueArray = new Uint8Array(value.buffer);
        const decodedValue = new TextDecoder('utf-8').decode(valueArray);

        if (char.uuid === 'c36e1c5a-fc6e-48c8-9a8e-d0b350399d0e') {
          macAddress = decodedValue;
        } else if (char.uuid === 'fbc47809-76ce-44fa-a2f0-676b95615473') {
          uniqueId = decodedValue;
        }
      }

      return { macAddress, uniqueId };
    } catch (error) {
      connectionAttempted.current = true; // Ensure the flag is set if the connection fails
      throw new Error('Failed to connect to the Bluetooth device.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold mb-6 text-center">Admin Login</h2>
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name:</label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Password:</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>
          {deviceConnectionFailed && (
            <div className="mb-4">
              <label className="block text-gray-700">Backup Code:</label>
              <input
                type="text"
                name="backupCode"
                value={backupCode}
                onChange={onChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
              <p className="text-gray-500 text-sm">Enter your backup code if you can't connect to your device.</p>
            </div>
          )}
          {loading ? (
            <p className="text-center text-gray-600">Verifying...</p>
          ) : (
            <button
              type="submit"
              className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Login
            </button>
          )}
        </form>
        
      </div>
    </div>
  );
};

export default AdminLogin;
