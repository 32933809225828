import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Modal,
  TextField,
  Grid
} from '@mui/material';
import axios from 'axios';
import Sidebaradmin from './Sidebaradmin';

const ApplicationManagement = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalApplications, setTotalApplications] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [scheduledDate, setScheduledDate] = useState('');
  const [outcome, setOutcome] = useState('');
  const [outcomeNotes, setOutcomeNotes] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOutcomeModalOpen, setIsOutcomeModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false); 
  const [applicantResume, setApplicantResume] = useState(''); 
  const [applicantAnswers, setApplicantAnswers] = useState([]);
  const [emailSearch, setEmailSearch] = useState(''); // New state for email search
  const [jobTitleSearch, setJobTitleSearch] = useState(''); // New state for job title search
 

  useEffect(() => {
    fetchApplications();
  }, [page, rowsPerPage]);

  const fetchApplications = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://besto-hrms.onrender.com/api/jobs/job-applications', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
          emails: emailSearch, // Pass email search as a parameter
          jobTitle: jobTitleSearch, // Pass job title search as a parameter
        }
      });
      setApplications(response.data.applications);
     
      setTotalApplications(response.data.totalPages * rowsPerPage);
    } catch (error) {
      console.error('Error fetching applications:', error);
      alert('Failed to fetch job applications. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearch = () => {
    setPage(0);
    fetchApplications();
  };


  const handleScheduleInterview = async (applicationId) => {
    try {
      await axios.put(`https://besto-hrms.onrender.com/api/jobs/job-applications/${applicationId}/schedule-interview`, {
        scheduledDate,
      });
      alert('Interview scheduled successfully');
      setIsModalOpen(false);
      fetchApplications();
    } catch (error) {
      console.error('Failed to schedule interview:', error);
      alert('Failed to schedule interview. Please try again.');
    }
  };

  const handleRescheduleInterview = async (applicationId) => {
    try {
      await axios.put(`https://besto-hrms.onrender.com/api/jobs/job-applications/${applicationId}/reschedule-interview`, {
        scheduledDate,
      });
      alert('Interview rescheduled successfully');
      setIsModalOpen(false);
      fetchApplications();
    } catch (error) {
      console.error('Failed to reschedule interview:', error);
      alert('Failed to reschedule interview. Please try again.');
    }
  };

  const handleUpdateOutcome = async (applicationId) => {
    try {
      await axios.put('https://besto-hrms.onrender.com/api/jobs/job-applications/${applicationId}/interview-outcome', {
        outcome,
        notes: outcomeNotes,
      });
      alert('Interview outcome updated successfully');
      setIsOutcomeModalOpen(false);
      fetchApplications();
    } catch (error) {
      console.error('Failed to update interview outcome:', error);
      alert('Failed to update interview outcome. Please try again.');
    }
  };

  const openScheduleModal = (application) => {
    setSelectedApplication(application);
    setScheduledDate('');
    setIsModalOpen(true);
  };

  const openOutcomeModal = (application) => {
    setSelectedApplication(application);
    setOutcome('');
    setOutcomeNotes('');
    setIsOutcomeModalOpen(true);
  };

  const openViewModal = (application) => {
    setSelectedApplication(application);
    setApplicantResume(application.resume); // Set the applicant's resume
    setApplicantAnswers(application.answers || []); // Set the applicant's answers
    setIsViewModalOpen(true);
  };

  return (
    <Grid container>
      <Grid item xs={2}>
        <Sidebaradmin />
      </Grid>
      <Grid item xs={10}>
        <Container sx={{ mt: 4 }}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
              Application Management
            </Typography>
            <Box sx={{ mb: 2 }}>
              <TextField
                label="Search by Email"
                value={emailSearch}
                onChange={(e) => setEmailSearch(e.target.value)}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Search by Job Title"
                value={jobTitleSearch}
                onChange={(e) => setJobTitleSearch(e.target.value)}
                sx={{ mr: 2 }}
              />
              <Button variant="contained" color="primary" onClick={handleSearch}>
                Search
              </Button>
            </Box>  
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Email</TableCell>
                        <TableCell>Job Title</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {applications.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            No applications found.
                          </TableCell>
                        </TableRow>
                      ) : (
                        applications.map((application) => (
                          <TableRow key={application._id}>
                            <TableCell>{application.userId?.emails || 'N/A'}</TableCell>
                            <TableCell>{application.jobId?.title || 'N/A'}</TableCell>
                            <TableCell>{application.interview?.status || 'Pending'}</TableCell>
                            <TableCell>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ mr: 1 }}
                                onClick={() => openScheduleModal(application)}
                              >
                                Schedule Interview
                              </Button>
                              <Button
                                variant="outlined"
                                color="secondary"
                                sx={{ mr: 1 }}
                                onClick={() => openOutcomeModal(application)}
                              >
                                Give Feedback
                              </Button>
                              <Button
                                variant="text"
                                color="default"
                                onClick={() => openViewModal(application)}
                              >
                                View
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={totalApplications}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </>
            )}
          </Paper>

          {/* Modal for Viewing Applicant Details */}
<Modal open={isViewModalOpen} onClose={() => setIsViewModalOpen(false)}>
  <Box sx={{ p: 4, backgroundColor: 'white', margin: 'auto', maxWidth: '600px' }}>
    <Typography variant="h6">Applicant Details</Typography>
    
    {/* Resume Link */}
    <Typography variant="subtitle1">Resume:</Typography>
    <Typography variant="body2" sx={{ mb: 2 }}>
      {applicantResume ? (
        <a href={`https://besto-hrms.onrender.com/${applicantResume}`} target="_blank" rel="noopener noreferrer">
          View Resume
        </a>
      ) : (
        'No resume available.'
      )}
    </Typography>

    {/* Answered Questions */}
    <Typography variant="subtitle1">Answered Questions:</Typography>
    {applicantAnswers.length > 0 ? (
      applicantAnswers.map((answer, index) => (
        <Typography key={index} variant="body2" sx={{ mb: 1 }}>
          {`Q${index + 1}: ${answer.questionText || 'N/A'} - A: ${answer.answerText}`}
        </Typography>
      ))
    ) : (
      <Typography variant="body2">No answered questions available.</Typography>
    )}
  </Box>
</Modal>


          {/* Modal for Scheduling/Rescheduling Interviews */}
          <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
            <Box sx={{ p: 4, backgroundColor: 'white', margin: 'auto', maxWidth: '400px' }}>
              <Typography variant="h6">Schedule Interview</Typography>
              <TextField
                label="Scheduled Date"
                type="datetime-local"
                fullWidth
                value={scheduledDate}
                onChange={(e) => setScheduledDate(e.target.value)}
                sx={{ mt: 2, mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleScheduleInterview(selectedApplication._id)}
              >
                Schedule
              </Button>
              <Button variant="outlined" onClick={() => setIsModalOpen(false)} sx={{ ml: 1 }}>
                Cancel
              </Button>
            </Box>
          </Modal>

          {/* Modal for Updating Outcome */}
          <Modal open={isOutcomeModalOpen} onClose={() => setIsOutcomeModalOpen(false)}>
            <Box sx={{ p: 4, backgroundColor: 'white', margin: 'auto', maxWidth: '400px' }}>
              <Typography variant="h6">Update Interview Outcome</Typography>
              <TextField
                label="Outcome"
                fullWidth
                value={outcome}
                onChange={(e) => setOutcome(e.target.value)}
                sx={{ mt: 2, mb: 2 }}
              />
              <TextField
                label="Notes"
                fullWidth
                multiline
                rows={4}
                value={outcomeNotes}
                onChange={(e) => setOutcomeNotes(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateOutcome(selectedApplication._id)}
              >
                Update
              </Button>
              <Button variant="outlined" onClick={() => setIsOutcomeModalOpen(false)} sx={{ ml: 1 }}>
                Cancel
              </Button>
            </Box>
          </Modal>
        </Container>
      </Grid>
    </Grid>
  );
};

export default ApplicationManagement;