import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Modal,
  Divider,
} from '@mui/material';
import axios from 'axios';
import Sidebaradmin from './Sidebaradmin';

const VendorManagement = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalVendors, setTotalVendors] = useState(0);
  const [selectedVendor, setSelectedVendors] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchVendors();
  }, [page, rowsPerPage]);

  const fetchVendors = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://besto-hrms.onrender.com/api/vendor/vendors', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      });
      setVendors(response.data.vendors);
      setTotalVendors(response.data.totalPages * rowsPerPage);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      alert('Failed to fetch vendors. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openViewModal = (vendor) => {
    setSelectedVendors(vendor);
    setIsModalOpen(true);
  };

  const handleStatusChange = async (vendorId, status) => {
    try {
      await axios.put(`https://besto-hrms.onrender.com/api/vendor/vendors/${vendorId}/status`, { status });
      alert('Status updated successfully');
      fetchVendors();
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
    <div className="w-64 bg-white shadow-md">
      <Sidebaradmin />
    </div>
    <Container sx={{ mt: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
          Vendor Management
        </Typography>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Vendor ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendors.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No vendors found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    vendors.map((vendor) => (
                      <TableRow key={vendor._id}>
                        <TableCell>{vendor.vendorId}</TableCell>
                        <TableCell>{`${vendor.vendorName}`}</TableCell>
                        <TableCell>{vendor.status}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1 }}
                            onClick={() => openViewModal(vendor)}
                          >
                            View
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleStatusChange(vendor._id, 'Verified')}
                          >
                            Verify
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleStatusChange(vendor._id, 'Rejected')}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalVendors}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}
      </Paper>

{/* Modal for Viewing Vendor Details */}
<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
  <Box
    sx={{
      p: 4,
      backgroundColor: '#f9f9f9',
      margin: 'auto',
      maxWidth: '600px',
      maxHeight: '80vh', 
      overflowY: 'auto',  
      borderRadius: '10px',
      boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
      textAlign: 'left',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    }}
  >
    <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ color: '#333', borderBottom: '2px solid #ddd', pb: 2 }}>
      Vendor Details
    </Typography>
    {selectedVendor && (
      <>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">Vendor ID:</Typography>
          <Typography variant="subtitle1">{selectedVendor.vendorId}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Vendor Name:</Typography>
          <Typography variant="subtitle1">{selectedVendor.vendorName}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Vendor Type:</Typography>
          <Typography variant="subtitle1">{selectedVendor.vendorType}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Company Name:</Typography>
          <Typography variant="subtitle1">{selectedVendor.companyName}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Email:</Typography>
          <Typography variant="subtitle1">{selectedVendor.email}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Contact:</Typography>
          <Typography variant="subtitle1">{selectedVendor.contact}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Date of Incorporation:</Typography>
          <Typography variant="subtitle1">{new Date(selectedVendor.dateOfIncorporation).toLocaleDateString()}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Activities:</Typography>
          <Typography variant="subtitle1">{selectedVendor.activities}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Status:</Typography>
          <Typography variant="subtitle1">{selectedVendor.status}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* POC Section */}
        <Typography variant="h6" fontWeight="bold" mt={2} sx={{ color: '#333' }}>
          Point of Contact (POC)
        </Typography>
        {selectedVendor.vendorPOC?.map((poc, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="body2">Name: {poc.name}</Typography>
            <Typography variant="body2">Designation: {poc.designation}</Typography>
            <Typography variant="body2">Contact: {poc.contact}</Typography>
            <Typography variant="body2">Email: {poc.email}</Typography>
          </Box>
        ))}

        <Divider sx={{ my: 3 }} />

        {/* Bank Details */}
        <Typography variant="h6" fontWeight="bold" mt={2} sx={{ color: '#333' }}>
          Bank Details
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="body2">Bank Name: {selectedVendor.bankDetails?.name}</Typography>
          <Typography variant="body2">Account Number: {selectedVendor.bankDetails?.account}</Typography>
          <Typography variant="body2">IFSC: {selectedVendor.bankDetails?.IFSC}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Address Section */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>Address</Typography>
          <Typography variant="body2">Country: {selectedVendor.address?.country}</Typography>
          <Typography variant="body2">State: {selectedVendor.address?.state}</Typography>
          <Typography variant="body2">City: {selectedVendor.address?.city}</Typography>
          <Typography variant="body2">PINCODE: {selectedVendor.address?.PINCODE}</Typography>
          <Typography variant="body2">Address: {selectedVendor.address?.address}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Document Section */}
        <Typography variant="h6" fontWeight="bold" mt={2} sx={{ color: '#333' }}>
          Documents
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'none', paddingLeft: 0, margin: '10px 0' }}>
          {[
            { label: 'Aadhar Card', file: selectedVendor.documents?.aadharCard },
            { label: 'PAN Card', file: selectedVendor.documents?.panCard },
            { label: 'TAN', file: selectedVendor.documents?.TAN },
            { label: 'GST', file: selectedVendor.documents?.GST },
            { label: 'MSME', file: selectedVendor.documents?.MSME },
          ].map((doc, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              <Typography variant="subtitle2">
                <strong>{doc.label}:</strong> {doc.file ? (
                  <a href={`https://besto-hrms.onrender.com/${doc.file}`} target="_blank" rel="noopener noreferrer">
                    View
                  </a>
                ) : 'Not uploaded'}
              </Typography>
            </li>
          ))}
        </Box>
      </>
    )}
  </Box>
</Modal>




    </Container>
    </div>
  );
};

export default VendorManagement;
