import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLogin from "./components/Superadmin/Login";
import Dashboard from "./components/Superadmin/Dashboard";
import Dashboardadmin from "./components/Admin/Dashboardadmin";
import PrivateRoute from "./components/PrivateRoute";
import GenerateCredentials from "./components/Superadmin/GenerateCredentials";
import Forbidden from "./components/Forbidden";
import BluetoothAuth from "./components/BluetoothAuth";
import Homepage from "./components/Homepage";
import Login from "./components/Login";
import VendorDashboard from "./components/Vendor/VendorDashboard";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import { ToastContainer } from "react-toastify";
import VendorRegister from "./components/Admin/VendorRegister";
import EmployeeRegister from "./components/Admin/EmployeeRegister";
import EmployeeDashboard from "./components/Employee/EmployeeDashboard";
import EmployeeProfile from "./components/Employee/EmployeeProfile";
import VendorProfile from "./components/Vendor/VendorProfile";
import JobPostForm from "./components/Admin/JobPostForm";
import JobList from "./components/JobList";
import JobDetail from "./components/JobDetail";
import MyApplications from "./components/MyApplications";
import ApplicationManagement from "./components/Admin/ApplicationManagement";
import EmployeeManagement from "./components/Admin/EmployeeManagement";
import VendorManagement from "./components/Admin/VendorManagement";
import CandidateRegister from "./components/Admin/CandidateRegister";
import JobManagement from "./components/Admin/JobManagement";
import CandidateManagement from "./components/Admin/CandidateManagement";
import UserList from "./components/Admin/UserList";
import UnscreenedCandidates from "./components/Admin/UnscreenedCandidates";
import AdminList from "./components/Superadmin/UserList";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />

        <Route path="/login" element={<Login />} />
        <Route path="/login-admin" element={<AdminLogin />} />
        <Route
          path="/vendor-register"
          element={
            <PrivateRoute requiredRole="admin">
              <VendorRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="/application-management"
          element={
            <PrivateRoute requiredRole="admin">
              <ApplicationManagement />
            </PrivateRoute>
          }
        />
         <Route
          path="/employee-management"
          element={
            <PrivateRoute requiredRole="admin">
              <EmployeeManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/users-management"
          element={
            <PrivateRoute requiredRole="admin">
              <UserList />
            </PrivateRoute>
          }
        />
        <Route
          path="/candidate-management"
          element={
            <PrivateRoute requiredRole="admin">
              <CandidateManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/existing-candidate-screening"
          element={
            <PrivateRoute requiredRole="admin">
              <UnscreenedCandidates />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendor-management"
          element={
            <PrivateRoute requiredRole="admin">
              <VendorManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee-register"
          element={
            <PrivateRoute requiredRole="admin">
              <EmployeeRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="/candidate-register"
          element={
            <PrivateRoute requiredRole="admin">
              <CandidateRegister />
            </PrivateRoute>
          }
        />
        <Route
          path="/job-post"
          element={
            <PrivateRoute requiredRole="admin">
              <JobPostForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/job-management"
          element={
            <PrivateRoute requiredRole="admin">
              <JobManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendor-dashboard"
          element={
            <PrivateRoute requiredRole="Vendor">
              <VendorDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee-dashboard"
          element={
            <PrivateRoute requiredRole="Employee">
              <EmployeeDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee-profile"
          element={
            <PrivateRoute requiredRole="Employee">
              <EmployeeProfile />
            </PrivateRoute>
          }
        />
        <Route path="/job-list" element={<JobList />} />
        <Route path="/job/:id" element={<JobDetail />} />
        <Route path="/my-applications" element={<MyApplications />} />

        <Route
          path="/vendor-profile"
          element={
            <PrivateRoute requiredRole="Vendor">
              <VendorProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute requiredRole="superadmin">
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/saadmin-management"
          element={
            <PrivateRoute requiredRole="superadmin">
              <AdminList />
            </PrivateRoute>
          }
        />
        <Route
          path="/generate-credentials"
          element={
            <PrivateRoute requiredRole="superadmin">
              <GenerateCredentials />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard-admin"
          element={
            <PrivateRoute requiredRole="admin">
              <Dashboardadmin />
            </PrivateRoute>
          }
        />
        <Route
          path="/bluetooth-auth"
          element={
            <PrivateRoute requiredRole="superadmin">
              <BluetoothAuth />
            </PrivateRoute>
          }
        />
        <Route path="/403" element={<Forbidden />} />
      </Routes>
      <Footer />
      <ToastContainer />
    </Router>
  );
};

export default App;
