import React, { useState, useEffect, useContext } from 'react';
import * as base64js from 'base64-js';
import { AuthContext } from '../context/AuthContext'; 
import Sidebar from '../components/Sidebar'; 

const BluetoothAuth = () => {
  const { auth } = useContext(AuthContext); 
  const [device, setDevice] = useState(null);
  const [authStatus, setAuthStatus] = useState('');
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [characteristicValues, setCharacteristicValues] = useState({});
  const [macAddress, setMacAddress] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [deviceInfo, setDeviceInfo] = useState({ name: '' });

  const requestDevice = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [
          { services: ['ebed0e09-033a-4bfe-8dcc-20a04fad944e'] }, 
        ],
      });

      setDevice(device);
      setDeviceInfo({ name: device.name || 'Unknown' });
      setAuthStatus('Device selected. Connecting...');
      await connectToDevice(device);
    } catch (error) {
      console.error('Error selecting Bluetooth device:', error);
      setAuthStatus(`Failed to select a device: ${error.message}`);
    }
  };

  const connectToDevice = async (device) => {
    try {
      const server = await device.gatt.connect();
      setAuthStatus('Connected to the device.');
      setDeviceConnected(true);
      await getDeviceServices(server);
    } catch (error) {
      console.error('Error connecting to the Bluetooth device:', error);
      setAuthStatus(`Failed to connect: ${error.message}`);
    }
  };

  const getDeviceServices = async (server) => {
    try {
      const service = await server.getPrimaryService('ebed0e09-033a-4bfe-8dcc-20a04fad944e');
      const characteristics = await service.getCharacteristics();

      characteristics.forEach((char) => {
        console.log(`Characteristic: ${char.uuid}`);
        if (char.properties.read) {
          readCharacteristicValue(char);
        }
      });
    } catch (error) {
      console.error('Error getting services and characteristics:', error);
      setAuthStatus(`Failed to retrieve services or characteristics: ${error.message}`);
    }
  };

  const readCharacteristicValue = async (characteristic) => {
    try {
      const value = await characteristic.readValue();
      const valueArray = new Uint8Array(value.buffer);
      const base64Value = base64js.fromByteArray(valueArray);
      const decodedValue = atob(base64Value);

      let formattedValue = decodedValue;

      
      if (characteristic.uuid === 'c36e1c5a-fc6e-48c8-9a8e-d0b350399d0e') {
        formattedValue = `MAC Address: ${decodedValue}`;
        setMacAddress(decodedValue); 
      } else if (characteristic.uuid === 'fbc47809-76ce-44fa-a2f0-676b95615473') {
        formattedValue = `Unique ID: ${decodedValue}`;
        setUniqueId(decodedValue); 
      }

      setCharacteristicValues((prev) => ({
        ...prev,
        [characteristic.uuid]: formattedValue,
      }));

    } catch (error) {
      console.error('Error reading characteristic value:', error);
      setAuthStatus(`Failed to read characteristic value: ${error.message}`);
    }
  };

  useEffect(() => {
    if (macAddress && uniqueId) {
      saveDeviceDetails(macAddress, uniqueId);
    }
  }, [macAddress, uniqueId]);

  const saveDeviceDetails = async (macAddress, uniqueId) => {
    try {
      const userId = auth.user._id; 

      const response = await fetch('https://besto-hrms.onrender.com/api/device/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ macAddress, uniqueId, userId }),
      });

      const data = await response.json();
      if (response.ok) {
        setAuthStatus('Device details saved successfully.');
      } else {
        setAuthStatus(`Failed to save device details: ${data.message}`);
      }
    } catch (error) {
      console.error('Error saving device details:', error);
      setAuthStatus(`Failed to save device details: ${error.message}`);
    }
  };

  useEffect(() => {
    if (deviceConnected) {
      const checkProximity = setInterval(() => {
        console.log('Checking proximity...');
      }, 5000);

      return () => clearInterval(checkProximity);
    }
  }, [deviceConnected]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-6">
        <h2 className="text-3xl font-semibold mb-6">Bluetooth Proximity Authentication</h2>
        <button
          onClick={requestDevice}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition"
        >
          Register Device for Bluetooth Authentication
        </button>
        {authStatus && <p className="mt-4 text-gray-700">{authStatus}</p>}
        {deviceInfo.name && <p className="mt-4 text-gray-800">Device Name: {deviceInfo.name}</p>}
        
        {Object.keys(characteristicValues).length > 0 && (
          <div className="mt-6 bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4">Characteristic Values:</h3>
            {Object.entries(characteristicValues).map(([uuid, value]) => (
              <p key={uuid} className="text-gray-800">{value}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BluetoothAuth;
