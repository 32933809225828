import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EmployeeProfile = () => {
  const [employeeData, setEmployeeData] = useState({
    employeeId: '',
    companyName: 'Baranwal Consultancy and Services',
    prefix: 'Mr.',
    firstName: '',
    lastName: '',
    personalEmail: '',
    officialEmail: '',
    personalContact: '',
    emergencyContact: '',
    emergencyContactRelation: '',
    dob: '',
    bloodGroup: '',
    gender: '',
    maritalStatus: '',
    permanentAddress: { country: '', state: '', city: '', address: '' },
    currentAddress: { country: '', state: '', city: '', address: '' },
    dateOfJoining: '',
    employmentType: 'Permanent',
    employmentStatus: 'Probation',
    department: 'Account',
    designation: '',
    reportingTo: '',
    documents: {
      aadharCard: null,
      panCard: null,
      passportPhoto: null, 
      addressProof: null,
      educationalDocs: { tenth: null, twelfth: null, graduation: null }, 
    },
    linkedinLink: '',
    
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeData((prevState) => ({ ...prevState, [name]: value }));
  };

  const navigate = useNavigate();

  const handleAddressChange = (addressType, field, value) => {
    setEmployeeData((prevState) => ({
      ...prevState,
      [addressType]: { ...prevState[addressType], [field]: value },
    }));
  };


// Handle file input change
const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
        if (['tenth', 'twelfth', 'graduation'].includes(name)) {
            // Handle educationalDocs separately
            setEmployeeData((prevState) => ({
                ...prevState,
                documents: {
                    ...prevState.documents,
                    educationalDocs: { ...prevState.documents.educationalDocs, [name]: file },
                },
            }));
        } else {
            // Handle other document fields
            setEmployeeData((prevState) => ({
                ...prevState,
                documents: { ...prevState.documents, [name]: file },
            }));
        }
    }
};

const handleSubmit = async (e) => {
    e.preventDefault();

    // Log employee data for debugging
    console.log("Employee Data:", employeeData);

    // Extract the documents part to upload separately
    const { documents, ...restOfEmployeeData } = employeeData;

    try {
        // 1. Send non-file employee data to employees endpoint
        await axios.post('https://besto-hrms.onrender.com/api/employee/employees', restOfEmployeeData, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        alert('Employee details saved successfully.');

        // 2. If there are files, upload the documents
        if (documents && Object.keys(documents).length > 0) {
            await uploadFiles(documents);  // Call the function to handle file upload
        }
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        alert('Error saving employee details. Please check the console for details.');
    }
};

// Function to upload files
const uploadFiles = async (documents) => {
    const formData = new FormData();

    // Append document files to FormData
    Object.keys(documents).forEach((key) => {
        const file = documents[key];
        if (key === 'educationalDocs' && file) {
            // Handle educational documents separately
            Object.keys(file).forEach((eduKey) => {
                const eduFile = file[eduKey];
                if (eduFile) {
                    formData.append(`educationalDocs[${eduKey}]`, eduFile);  // Add each educational doc file
                }
            });
        } else if (file) {
            formData.append(key, file);  // Add other document files like aadharCard, panCard, etc.
        }
    });

    try {
        // Send the formData with the files to the upload endpoint
        await axios.post('https://besto-hrms.onrender.com/api/employee/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        alert('Files uploaded successfully.');
    } catch (err) {
        console.error("Error response:", err.response ? err.response.data : err);
        alert('Error uploading files. Please check the console for details.');
    }
};








  return (
    <form onSubmit={handleSubmit} className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold mb-6">Employee Profile</h2>

      {/* Employee ID */}
      <div className="mb-4">
        <label className="block text-gray-700">Employee ID</label>
        <input
          type="text"
          name="employeeId"
          value={employeeData.employeeId}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      {/* Company Name */}
      <div className="mb-4">
        <label className="block text-gray-700">Company Name</label>
        <select
          name="companyName"
          value={employeeData.companyName}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="Baranwal Consultancy and Services">Baranwal Consultancy and Services</option>
          <option value="Bestowal Systems and Services Pvt. Ltd.">Bestowal Systems and Services Pvt. Ltd.</option>
        </select>
      </div>

      {/* Prefix */}
      <div className="mb-4">
        <label className="block text-gray-700">Prefix</label>
        <select
          name="prefix"
          value={employeeData.prefix}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value="Mr.">Mr.</option>
          <option value="Ms.">Ms.</option>
        </select>
      </div>

      {/* First Name, Last Name */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">First Name</label>
          <input
            type="text"
            name="firstName"
            value={employeeData.firstName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={employeeData.lastName}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      {/* Emails */}
      <div className="mb-4">
        <label className="block text-gray-700">Personal Email</label>
        <input
          type="email"
          name="personalEmail"
          value={employeeData.personalEmail}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Official Email</label>
        <input
          type="email"
          name="officialEmail"
          value={employeeData.officialEmail}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      {/* Contact Information */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Personal Contact</label>
          <input
            type="text"
            name="personalContact"
            value={employeeData.personalContact}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Emergency Contact</label>
          <input
            type="text"
            name="emergencyContact"
            value={employeeData.emergencyContact}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Emergency Contact Relation</label>
        <input
          type="text"
          name="emergencyContactRelation"
          value={employeeData.emergencyContactRelation}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      {/* Additional Fields */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={employeeData.dob}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Blood Group</label>
          <input
            type="text"
            name="bloodGroup"
            value={employeeData.bloodGroup}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Gender</label>
        <select
          name="gender"
          value={employeeData.gender}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="">Select</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Marital Status</label>
        <select
          name="maritalStatus"
          value={employeeData.maritalStatus}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Single">Single</option>
          <option value="Married">Married</option>
        </select>
      </div>

      {/* Address Fields */}
      <h3 className="text-lg font-semibold mt-6">Permanent Address</h3>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <div>
          <label className="block text-gray-700">Country</label>
          <input
            type="text"
            value={employeeData.permanentAddress.country}
            onChange={(e) => handleAddressChange('permanentAddress', 'country', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">State</label>
          <input
            type="text"
            value={employeeData.permanentAddress.state}
            onChange={(e) => handleAddressChange('permanentAddress', 'state', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">City</label>
          <input
            type="text"
            value={employeeData.permanentAddress.city}
            onChange={(e) => handleAddressChange('permanentAddress', 'city', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Address</label>
          <input
            type="text"
            value={employeeData.permanentAddress.address}
            onChange={(e) => handleAddressChange('permanentAddress', 'address', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      <h3 className="text-lg font-semibold mt-6">Current Address</h3>
      <div className="mb-4 grid grid-cols-4 gap-4">
        <div>
          <label className="block text-gray-700">Country</label>
          <input
            type="text"
            value={employeeData.currentAddress.country}
            onChange={(e) => handleAddressChange('currentAddress', 'country', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">State</label>
          <input
            type="text"
            value={employeeData.currentAddress.state}
            onChange={(e) => handleAddressChange('currentAddress', 'state', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">City</label>
          <input
            type="text"
            value={employeeData.currentAddress.city}
            onChange={(e) => handleAddressChange('currentAddress', 'city', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div>
          <label className="block text-gray-700">Address</label>
          <input
            type="text"
            value={employeeData.currentAddress.address}
            onChange={(e) => handleAddressChange('currentAddress', 'address', e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Date of Joining</label>
        <input
          type="date"
          name="dateOfJoining"
          value={employeeData.dateOfJoining}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Employment Type</label>
        <select
          name="employmentType"
          value={employeeData.employmentType}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Permanent">Permanent</option>
          <option value="Trainee">Trainee</option>
          <option value="Intern">Intern</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Employment Status</label>
        <select
          name="employmentStatus"
          value={employeeData.employmentStatus}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Probation">Probation</option>
          <option value="Confirmed">Confirmed</option>
          <option value="Resigned">Resigned</option>
          <option value="Relieved">Relieved</option>
          <option value="Settled">Settled</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Department</label>
        <select
          name="department"
          value={employeeData.department}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        >
          <option value="Account">Account</option>
          <option value="Operations">Operations</option>
          <option value="Human Resources">Human Resources</option>
          <option value="IT">IT</option>
          <option value="Sales and Marketing">Sales and Marketing</option>
        </select>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Designation</label>
        <input
          type="text"
          name="designation"
          value={employeeData.designation}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Reporting To</label>
        <input
          type="text"
          name="reportingTo"
          value={employeeData.reportingTo}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
          required // Assuming this field is required based on the error
        />
      </div>

      {/* Document Uploads */}
      <h3 className="text-lg font-semibold mt-6">Documents Upload</h3>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Aadhar Card</label>
          <input
            type="file"
            name="aadharCard"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
            
          />
        </div>
        <div>
          <label className="block text-gray-700">PAN Card</label>
          <input
            type="file"
            name="panCard"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
            
          />
        </div>
      </div>
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-gray-700">Passport Photo</label> {/* Changed label */}
          <input
            type="file"
            name="passportPhoto" // Changed name to match state
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
            
          />
        </div>
        <div>
          <label className="block text-gray-700">Address Proof</label>
          <input
            type="file"
            name="addressProof"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 rounded"
            
          />
        </div>
      </div>
      {/* Separate inputs for educational documents */}
      <div className="mb-4">
        <label className="block text-gray-700">10th Grade Certificate</label>
        <input
          type="file"
          name="tenth"
          onChange={handleFileChange}
          className="w-full p-2 border border-gray-300 rounded"
          
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">12th Grade Certificate</label>
        <input
          type="file"
          name="twelfth"
          onChange={handleFileChange}
          className="w-full p-2 border border-gray-300 rounded"
          
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Graduation Certificate</label>
        <input
          type="file"
          name="graduation"
          onChange={handleFileChange}
          className="w-full p-2 border border-gray-300 rounded"
          
        />
      </div>

     

      <div className="mb-4">
        <label className="block text-gray-700">LinkedIn Link</label>
        <input
          type="url"
          name="linkedinLink"
          value={employeeData.linkedinLink}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 text-white p-2 rounded"
      >
        Save Employee
      </button>
    </form>
  );
};

export default EmployeeProfile;
