import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../Sidebar';

const Dashboard = () => {
  const {auth} = useContext(AuthContext);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const res = await axios.get('https://besto-hrms.onrender.com/api/auth/me');
        setUser(res.data);
      } catch (err) {
        console.error(err);
      }
    };

    if (auth.token) {
      fetchUser();
    }
  }, [auth.token]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-6">
        <h2 className="text-3xl font-semibold mb-6">Dashboard</h2>
        {user ? (
          <div className="bg-white shadow-md rounded-lg p-6">
            <p className="text-lg mb-4">
              <strong className="text-blue-600">Welcome, {user.role}</strong>
              <br />
              <span className="text-gray-800">{user.name}!</span>
            </p>
            
          </div>
        ) : (
          <p className="text-gray-600">Loading user data...</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
