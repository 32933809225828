import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  TextField,
  InputLabel,
  Select,
  FormControl,
  Box,
  Container,
  Paper,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TablePagination,
  Modal,
  Divider,
} from '@mui/material';
import axios from 'axios';
import Sidebaradmin from './Sidebaradmin';

const EmployeeManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trackData, setTrackData] = useState(null);
const [isTrackModalOpen, setIsTrackModalOpen] = useState(false);

const openTrackModal = async (employeeId) => {
  setLoading(true);
  try {
    if (!employeeId || employeeId.length !== 24) {
      throw new Error('Invalid employee ID');
    }
    const response = await axios.get(`https://besto-hrms.onrender.com/api/employee/applications/${employeeId}`);
    console.log(response.data)
    setTrackData(response.data);  
    setIsTrackModalOpen(true);
  } catch (error) {
    console.error('Error fetching tracking details:', error);
    alert('Failed to fetch tracking details. Please try again.');
  } finally {
    setLoading(false);
  }
};

// Close tracking modal
const closeTrackModal = () => {
  setIsTrackModalOpen(false);
  setTrackData(null);
};


  useEffect(() => {
    fetchEmployees();
  }, [page, rowsPerPage]);

  const fetchEmployees = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://besto-hrms.onrender.com/api/employee/employees', {
        params: {
          page: page + 1,
          limit: rowsPerPage,
        },
      });
      setEmployees(response.data.employees);
      setTotalEmployees(response.data.totalPages * rowsPerPage);
    } catch (error) {
      console.error('Error fetching employees:', error);
      alert('Failed to fetch employees. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openViewModal = (employee) => {
    setSelectedEmployee(employee);
    setIsModalOpen(true);
  };

  const handleStatusChange = async (employeeId, status) => {
    try {
      await axios.put(`https://besto-hrms.onrender.com/api/employee/employees/${employeeId}/status`, { status });
      alert('Status updated successfully');
      fetchEmployees();
    } catch (error) {
      console.error('Failed to update status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [outcomeFilter, setOutcomeFilter] = useState('');

   // Filter trackData based on search and filters
   const filteredData = trackData?.filter((item) => {
    const matchesTitle = item.jobId?.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = statusFilter ? item.interview?.status === statusFilter : true;
    const matchesOutcome = outcomeFilter ? item.interview?.outcome === outcomeFilter : true;
    return matchesTitle && matchesStatus && matchesOutcome;
  });

  return (
    <div className="flex h-screen bg-gray-100">
    <div className="w-64 bg-white shadow-md">
      <Sidebaradmin />
    </div>
    <Container sx={{ mt: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
          Employee Management
        </Typography>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Profile Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employees.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        No employees found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    employees.map((employee) => (
                      <TableRow key={employee._id}>
                        <TableCell>{employee.employeeId}</TableCell>
                        <TableCell>{`${employee.prefix} ${employee.firstName} ${employee.lastName}`}</TableCell>
                        <TableCell>{employee.status}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: 1 }}
                            onClick={() => openViewModal(employee)}
                          >
                            View
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            sx={{ mr: 1 }}
                            onClick={() => openTrackModal(employee.user)}
                          >
                            Track
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleStatusChange(employee._id, 'Verified')}
                          >
                            Verify
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleStatusChange(employee._id, 'Rejected')}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalEmployees}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </>
        )}
      </Paper>

{/* Modal for Viewing Employee Details */}
<Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
  <Box
    sx={{
      p: 4,
      backgroundColor: '#f9f9f9',
      margin: 'auto',
      maxWidth: '600px',
      maxHeight: '80vh', 
      overflowY: 'auto',  
      borderRadius: '10px',
      boxShadow: '0 8px 40px rgba(0, 0, 0, 0.2)',
      textAlign: 'left',
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
    }}
  >
    <Typography variant="h5" fontWeight="bold" gutterBottom sx={{ color: '#333', borderBottom: '2px solid #ddd', pb: 2 }}>
      Employee Details
    </Typography>
    {selectedEmployee && (
      <>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">ID:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.employeeId}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Name:</Typography>
          <Typography variant="subtitle1">{`${selectedEmployee.prefix} ${selectedEmployee.firstName} ${selectedEmployee.lastName}`}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Company:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.companyName}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Email:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.personalEmail}</Typography>
          
          <Typography variant="subtitle1" fontWeight="bold">Phone:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.personalContact}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Department:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.department}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Designation:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.designation}</Typography>

          <Typography variant="subtitle1" fontWeight="bold">Status:</Typography>
          <Typography variant="subtitle1">{selectedEmployee.status}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Address Sections */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>Permanent Address</Typography>
          <Typography variant="body2">Country: {selectedEmployee.permanentAddress?.country}</Typography>
          <Typography variant="body2">State: {selectedEmployee.permanentAddress?.state}</Typography>
          <Typography variant="body2">City: {selectedEmployee.permanentAddress?.city}</Typography>
          <Typography variant="body2">Address: {selectedEmployee.permanentAddress?.address}</Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>Current Address</Typography>
          <Typography variant="body2">Country: {selectedEmployee.currentAddress?.country}</Typography>
          <Typography variant="body2">State: {selectedEmployee.currentAddress?.state}</Typography>
          <Typography variant="body2">City: {selectedEmployee.currentAddress?.city}</Typography>
          <Typography variant="body2">Address: {selectedEmployee.currentAddress?.address}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        {/* Document Section */}
        <Typography variant="h6" fontWeight="bold" mt={2} sx={{ color: '#333' }}>
          Documents
        </Typography>
        <Box component="ul" sx={{ listStyleType: 'none', paddingLeft: 0, margin: '10px 0' }}>
          {[
            { label: 'Aadhar Card', file: selectedEmployee.documents?.aadharCard },
            { label: 'PAN Card', file: selectedEmployee.documents?.panCard },
            { label: 'Passport Photo', file: selectedEmployee.documents?.passportPhoto, isImage: true },
            { label: 'Address Proof', file: selectedEmployee.documents?.addressProof },
            { label: '10th Certificate', file: selectedEmployee.documents?.educationalDocs?.tenth },
            { label: '12th Certificate', file: selectedEmployee.documents?.educationalDocs?.twelfth },
            { label: 'Graduation Certificate', file: selectedEmployee.documents?.educationalDocs?.graduation },
          ].map((doc, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              <Typography variant="subtitle2">
                <strong>{doc.label}:</strong> {doc.file ? (
                  doc.isImage ? (
                    <img
                      src={`https://besto-hrms.onrender.com/${doc.file}`}
                      alt={doc.label}
                      style={{ width: '100px', borderRadius: '4px', display: 'block', marginTop: '10px' }}
                    />
                  ) : (
                    <a href={`https://besto-hrms.onrender.com/${doc.file}`} target="_blank" rel="noopener noreferrer">
                      View
                    </a>
                  )
                ) : 'Not uploaded'}
              </Typography>
            </li>
          ))}
        </Box>
      </>
    )}
  </Box>
</Modal>
<Modal open={isTrackModalOpen} onClose={closeTrackModal}>
      <Box sx={{
        p: 4,
        backgroundColor: '#fff',
        margin: 'auto',
        maxWidth: '700px',
        maxHeight: '80vh', 
        overflowY: 'auto',  
        borderRadius: '12px',
        boxShadow: '0 12px 48px rgba(0, 0, 0, 0.15)',
        textAlign: 'left',
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
      }}>
        <Typography variant="h5" gutterBottom>Tracking Details</Typography>

        {/* Search Bar */}
        <TextField 
          label="Search by Job Title" 
          variant="outlined" 
          fullWidth 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Filters */}
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              label="Status"
            >
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="Under Review">Under Review</MenuItem>
              <MenuItem value="Scheduled">Scheduled</MenuItem>
              <MenuItem value="Interviewed">Interviewed</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Outcome</InputLabel>
            <Select
              value={outcomeFilter}
              onChange={(e) => setOutcomeFilter(e.target.value)}
              label="Outcome"
            >
              <MenuItem value="">All Outcomes</MenuItem>
              <MenuItem value="Not scheduled">Not scheduled</MenuItem>
              <MenuItem value="Passed">Passed</MenuItem>
              <MenuItem value="Failed">Failed</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* Track Data */}
        {filteredData && filteredData.length > 0 ? (
          filteredData.map((item) => (
            <Box key={item._id} sx={{ mb: 4, backgroundColor: '#f9f9f9', p: 3, borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
              <Typography variant="h6" gutterBottom><strong>Application ID:</strong> {item._id}</Typography>
              <Typography variant="subtitle1"><strong>Job Title:</strong> {item.jobId?.title}</Typography>
              <Typography variant="subtitle1"><strong>Job Description:</strong> {item.jobId?.description}</Typography>
              <Typography variant="subtitle1"><strong>Applied At:</strong> {new Date(item.appliedAt).toLocaleString()}</Typography>
              <Typography variant="subtitle1"><strong>Status:</strong> {item.interview?.status || "Under Review"}</Typography>
              <Typography variant="subtitle1"><strong>Outcome:</strong> {item.interview?.outcome || "Not scheduled"}</Typography>
              <Typography variant="subtitle1"><strong>Scheduled Date:</strong> {item.interview?.scheduledDate ? new Date(item.interview.scheduledDate).toLocaleString() : "N/A"}</Typography>

              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>Answers</Typography>
              {item.answers && item.answers.length > 0 ? (
                item.answers.map((answer, answerIndex) => (
                  <Box key={answerIndex} sx={{ mb: 1 }}>
                    <Typography variant="subtitle2"><strong>Question:</strong> {answer.questionText || "Question not available"}</Typography>
                    <Typography variant="subtitle2"><strong>Answer:</strong> {answer.answerText || "No answer provided"}</Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="subtitle2">No answers provided.</Typography>
              )}
            </Box>
          ))
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>






    </Container>
    </div>
  );
};

export default EmployeeManagement;
