import React from 'react';
import { Link } from 'react-router-dom'; 
const Footer = () => {
  return (
    <footer className="bg-blue-600 text-white py-6 mt-8 shadow-md">
      <div className="container mx-auto text-center">
        <p className="text-lg font-medium">&copy; 2024 BestoHRMS. All rights reserved.</p>
        <p className="text-sm mt-2">Designed and built by Besto Team</p>
        <div className="mt-4 flex justify-center space-x-6">
          <Link to="/privacy" className="hover:text-gray-300 transition">Privacy Policy</Link>
          <Link to="/terms" className="hover:text-gray-300 transition">Terms of Service</Link>
          <Link to="/contact" className="hover:text-gray-300 transition">Contact Us</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
