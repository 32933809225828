import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import * as base64js from 'base64-js';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GenerateCredentials = () => {
  const { auth } = useContext(AuthContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [device, setDevice] = useState(null);
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [macAddress, setMacAddress] = useState('');
  const [uniqueId, setUniqueId] = useState('');
  const [deviceInfo, setDeviceInfo] = useState({ name: '' });

  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const notifyInfo = (message) => toast.info(message);

  const requestDevice = async () => {
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ services: ['ebed0e09-033a-4bfe-8dcc-20a04fad944e'] }],
      });

      setDevice(device);
      setDeviceInfo({ name: device.name || 'Unknown' });
      notifyInfo('Device selected. Connecting...');
      await connectToDevice(device);
    } catch (error) {
      notifyError(`Failed to select a device: ${error.message}`);
    }
  };

  const connectToDevice = async (device) => {
    try {
      const server = await device.gatt.connect();
      notifySuccess('Connected to the device.');
      setDeviceConnected(true);
      await getDeviceServices(server);
    } catch (error) {
      notifyError(`Failed to connect: ${error.message}`);
    }
  };

  const getDeviceServices = async (server) => {
    try {
      const service = await server.getPrimaryService('ebed0e09-033a-4bfe-8dcc-20a04fad944e');
      const characteristics = await service.getCharacteristics();

      characteristics.forEach((char) => {
        if (char.properties.read) {
          readCharacteristicValue(char);
        }
      });
    } catch (error) {
      notifyError(`Failed to retrieve services or characteristics: ${error.message}`);
    }
  };

  const readCharacteristicValue = async (characteristic) => {
    try {
      const value = await characteristic.readValue();
      const valueArray = new Uint8Array(value.buffer);
      const base64Value = base64js.fromByteArray(valueArray);
      const decodedValue = atob(base64Value);

      if (characteristic.uuid === 'c36e1c5a-fc6e-48c8-9a8e-d0b350399d0e') {
        setMacAddress(decodedValue);
      } else if (characteristic.uuid === 'fbc47809-76ce-44fa-a2f0-676b95615473') {
        setUniqueId(decodedValue);
      }
    } catch (error) {
      notifyError(`Failed to read characteristic value: ${error.message}`);
    }
  };

  const handleGenerate = async () => {
    if (!macAddress || !uniqueId) {
      notifyError('Please register the device first.');
      return;
    }

    try {
      const res = await axios.post('https://besto-hrms.onrender.com/api/auth/register-admin', {
        name,
        email,
        password,
        role,
        macAddress,
        uniqueId,
      });

      notifySuccess(res.data.message);
      setName('');
      setPassword('');
      setDeviceConnected(false);
    } catch (err) {
      notifyError('Error generating credentials');
    }
  };

  useEffect(() => {
    if (deviceConnected) {
      const checkProximity = setInterval(() => {
        console.log('Checking proximity...');
      }, 5000);

      return () => clearInterval(checkProximity);
    }
  }, [deviceConnected]);

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 p-6">
        <h2 className="text-3xl font-semibold mb-6">Generate Admin Credentials with Bluetooth Authentication</h2>
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter name"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Email:</label>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter name"
          />
        </div>
        <div className="mb-4">
        <label className="block text-lg font-medium mb-2">Role:</label>
  <select
    value={role}
    onChange={(e) => setRole(e.target.value)}
    className="w-full p-2 border border-gray-300 rounded-md"
  >
    <option value="superadmin">Superadmin</option>
    <option value="admin">Admin</option>
  </select>
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
            placeholder="Enter password"
          />
        </div>
        <button
          onClick={handleGenerate}
          className={`${
            deviceConnected ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
          } text-white px-4 py-2 rounded-md transition`}
          disabled={!deviceConnected}
        >
          Save Credentials
        </button>
        <button
          onClick={requestDevice}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition ml-4"
        >
          Register Bluetooth Device
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default GenerateCredentials;
