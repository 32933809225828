import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Sidebaradmin from './Sidebaradmin';

const EmployeeRegister = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState(''); // Single email
  const [contactNumber, setContactNumber] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleRegister = async () => {
    setLoading(true);

    // Check if the email field is valid
    if (!email || email.trim() === '') {
      toast.error('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    try {
      const res = await axios.post('https://besto-hrms.onrender.com/api/auth/register-employee', {
        name,
        email,  // Send single email
        contactNumber,
        password,
        role: 'Employee',  
      });

      toast.success(res.data.message);
      setName('');
      setEmail(''); // Reset email
      setContactNumber('');
      setPassword('');
    } catch (error) {
      console.error('Registration failed:', error);
      toast.error('Error registering employee, possibly email id already exists', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebaradmin />
      <div className="flex-1 p-8">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-4xl mx-auto">
          <h2 className="text-4xl font-semibold text-gray-800 mb-8">Employee Registration</h2>
          
          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Employee Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter employee name"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Contact Number:</label>
            <input
              type="text"
              value={contactNumber}
              onChange={(e) => setContactNumber(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter contact number"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter password"
            />
          </div>

          <div className="mb-6">
            <label className="block text-lg font-medium text-gray-700 mb-2">Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter email"
            />
          </div>

          <div className="flex justify-end">
            <button
              type="button"
              className={`${
                loading ? 'bg-gray-400' : 'bg-green-500 hover:bg-green-600'
              } text-white px-6 py-3 rounded-lg transition focus:outline-none`}
              onClick={handleRegister}
              disabled={loading}
            >
              {loading ? 'Registering...' : 'Register Employee'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EmployeeRegister;
